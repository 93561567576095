import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography
} from '@esgian/esgianui';
import { FLOW, OIL_COMPANY_ACTIONS, RIG_SUPPLIER_ACTIONS } from '@store/AppReducer';
import { Datepicker, localeEn, snackbar } from '@mobiscroll/react';
import { useStore } from '@store/Store';
import PropTypes from 'prop-types';
import { CONTRACT_STATUSES } from '@constants';
import moment from 'moment';
import RigSelect from '@components/Inputs/RigSelect/RigSelect';

function AssignRequirementModal({
  disableResourceRig,
  flow,
  projectId,
  handleClose,
  requirementEvent,
  open
}) {
  const [startDate, setStartDate] = useState(null);
  const [dayRate, setDayRate] = useState(null);
  const [selectedRig, setSelectedRig] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setError] = useState({ start: null, status: null });
  const [contractStatus, setContractStatus] = useState(CONTRACT_STATUSES[5].id);
  const {
    state: { filterLookups },
    dispatch
  } = useStore();
  const { rigs: lookupRigs } = filterLookups;

  const validateFields = useCallback(() => {
    let tempErrors = {
      start: !startDate ? 'Required' : moment(startDate).isValid() ? null : 'Invalid start date',
      status: contractStatus === '' ? 'Required' : null,
      rig: selectedRig === '' ? 'Required' : null
    };
    setError(tempErrors);
    return Object.entries(tempErrors).filter(([_, v]) => v != null).length === 0;
  }, [startDate, contractStatus, selectedRig]);

  const handleAssignClick = useCallback(() => {
    if (!validateFields()) return;
    setLoading(true);
    let type = FLOW.RIG_SUPPLIER
      ? RIG_SUPPLIER_ACTIONS.ASSIGN_REQUIREMENT_TO_RIG
      : OIL_COMPANY_ACTIONS.ASSIGN_REQUIREMENT_TO_RIG;

    let resourceRig = lookupRigs?.find(({ rigId }) => rigId === parseInt(selectedRig));
    dispatch({
      type: type,
      flow: flow,
      payload: {
        projectId,
        requirement: {
          ...requirementEvent.details,
          ...{
            isCustom: true,
            contractStatusId: contractStatus,
            contractStatus: CONTRACT_STATUSES.find(({ id }) => id === contractStatus).name,
            dayrate: dayRate,
            startDate: moment(startDate).format('yyyy-MM-DD'),
            endDate: moment(startDate)
              .add(requirementEvent.details.duration, 'days')
              .format('yyyy-MM-DD')
          }
        },
        rigId: resourceRig.rigId,
        rigName: resourceRig.rigName
      }
    });
    snackbar({
      message: `Requirement assigned`,
      color: 'success',
      duration: 5000,
      display: 'top'
    });

    handleClose();
  }, [requirementEvent, lookupRigs, startDate, contractStatus, dayRate, selectedRig]);

  useEffect(() => {
    if (!requirementEvent) return;
    const { details } = requirementEvent;
    setStartDate(details.startDate);
    setDayRate(details.dayrate);

    if (!disableResourceRig) {
      let resourceRigId = requirementEvent.resource;
      if (typeof resourceRigId === 'string') {
        const ids = requirementEvent.resource.split(',');
        resourceRigId = parseInt(ids[1]);
        if (flow === FLOW.OIL_COMPANY && ids[0] !== 'rig') {
          resourceRigId = parseInt(ids[2]);
        }
      }
      setSelectedRig(resourceRigId);
    }
  }, [requirementEvent]);

  return (
    <Modal
      size={'sm'}
      handleClose={handleClose}
      title={`Assign requirement to rig`}
      open={open}
      actions={[
        <Button
          disableElevation
          onClick={handleClose}
          color={'secondary'}
          key={'cancel-changes-module'}>
          Cancel
        </Button>,
        <Button
          // disabled={!startDate || !contractStatus}
          color={'secondary'}
          disableElevation
          onClick={handleAssignClick}
          key={'apply-changes'}
          variant={'contained'}>
          save
        </Button>
      ]}>
      <Grid container sx={{ minHeight: '30vh' }}>
        {!loading && (
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Stack>
                <Stack>
                  <Typography color={errors.rig ? 'error' : 'text.primary'} variant={'body2'}>
                    RIG*
                  </Typography>
                  <RigSelect
                    error={errors.rig}
                    flow={flow}
                    handleChange={(val) => setSelectedRig(val)}
                    selectedRig={selectedRig}
                  />
                  {!!errors.rig && (
                    <Typography variant={'caption'} color={'error'}>
                      {errors.rig}
                    </Typography>
                  )}
                </Stack>
                <Typography color={errors.start ? 'error' : 'text.primary'} variant={'body2'}>
                  START DATE*
                </Typography>
                <Datepicker
                  error={!!errors.start}
                  inputStyle={'outline'}
                  dateFormat={'DD/MM/YYYY'}
                  locale={localeEn}
                  startInput={startDate}
                  returnFormat={'moment'}
                  value={startDate}
                  onChange={({ value }) => {
                    setStartDate(value);
                  }}
                />
                {errors.start && (
                  <Typography color={'error'} variant={'caption'} sx={{ ml: '14px', mt: '3px' }}>
                    {errors.start}
                  </Typography>
                )}
              </Stack>
              <Stack>
                <Typography variant={'body2'} color={errors.status ? 'error' : 'text.primary'}>
                  CONTRACT STATUS*
                </Typography>
                <TextField
                  helperText={errors.status}
                  select
                  error={!!errors.status}
                  variant={'outlined'}
                  value={contractStatus}
                  onChange={({ target }) => setContractStatus(target.value)}>
                  <MenuItem value={CONTRACT_STATUSES[5].id}>{CONTRACT_STATUSES[5].name}</MenuItem>
                  <MenuItem value={CONTRACT_STATUSES[6].id}>{CONTRACT_STATUSES[6].name}</MenuItem>
                </TextField>
              </Stack>
              <Stack>
                <Typography variant={'body2'}>DAY RATE</Typography>
                <OutlinedInput
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  type={'number'}
                  value={dayRate || ''}
                  variant={'outlined'}
                  placeholder={'Day rate...'}
                  onChange={({ target }) => setDayRate(parseInt(target.value))}
                />
              </Stack>
            </Stack>
          </Grid>
        )}
        {loading && (
          <Grid item xs={12} sx={{ alignSelf: 'center', textAlign: 'center' }}>
            <CircularProgress size={'6em'} />
          </Grid>
        )}
      </Grid>
    </Modal>
  );
}

AssignRequirementModal.propTypes = {
  flow: PropTypes.oneOf(Object.values(FLOW)),
  handleClose: PropTypes.func.isRequired,
  requirementEvent: PropTypes.object,
  open: PropTypes.bool,
  disableResourceRig: PropTypes.bool,
  projectId: PropTypes.number
};

AssignRequirementModal.defaultProps = {
  flow: FLOW.OIL_COMPANY,
  open: false,
  disableResourceRig: false,
  projectId: null
};

export default AssignRequirementModal;

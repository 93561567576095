export const APP_VERSION = 1.0;
export const ROUTES = {
  PROJECTS_OVERVIEW: '/market-planner'
};

export const PROJECT_TYPES = {
  RIG_OPERATOR: { id: 1, name: 'Scenario Planner for Rig Manager/Owner' },
  OIL_COMPANY: { id: 2, name: 'Scenario Planner for Operator' }
};

export const RIG_TYPE = {
  SEMISUBMERSIBLE: 1,
  JACKUP: 2,
  TENDER_SEMI: 3,
  TENDER_BARGE: 4,
  DRILLSHIP: 5,
  DRILLSHIP_WITH_PRODUCTION: 6,
  DRILLSHIP_WELL_INTERVENTION: 7,
  SEMI_ACC: 8,
  MONOHULL: 9,
  SWATH: 10
};

export const MONTH_SHORTNAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const ACTIVITY_STATUSES = [
  {
    id: 1,
    name: 'Warm / hot stacked',
    color: '#9B76DD',
    isFixed: true
  },
  {
    id: 2,
    name: 'Drilling',
    color: '#D4C5F0',
    isFixed: true
  },
  {
    id: 3,
    name: 'Cold stacked',
    color: '#E7BCDE',
    isFixed: true
  },
  {
    id: 4,
    name: 'Under construction',
    color: '#9E1290',
    isFixed: true
  },
  {
    id: 5,
    name: 'Undergoing repair / SPS',
    color: '#C763B3',
    isFixed: true
  }
];

export const CONTRACT_STATUSES = [
  {
    id: 1,
    name: 'Planned',
    color: '#0895C1',
    isFixed: false
  },
  {
    id: 2,
    name: 'Tender',
    color: '#886861',
    isFixed: false
  },
  {
    id: 3,
    name: 'Pre-Tender',
    color: '#546803',
    isFixed: false
  },
  {
    id: 4,
    name: 'Possible',
    color: '#BAA42F',
    isFixed: false
  },
  {
    id: 5,
    name: 'Direct negotiation',
    color: '#929292',
    isFixed: false
  },
  {
    id: 6,
    name: 'Confirmed',
    color: '#15218E',
    isFixed: true
  },
  {
    id: 7,
    name: 'Option',
    color: '#46B0D0',
    isFixed: true
  }
];

export const LOOKUP_NUM_BOP_STACKS = [
  { id: 1, name: '1' },
  { id: 2, name: '2' }
];

export const EVENT_TYPES = {
  CONTRACT: 'Contract',
  REQUIREMENT: 'Requirement',
  ACTIVITY: 'Activity'
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from '@store/Store';
import { useParams } from 'react-router-dom';
import { FLOW, GENERAL_ACTIONS, OIL_COMPANY_ACTIONS } from '@store/AppReducer';
import { snackbar } from '@mobiscroll/react';
import { getProject } from '@helpers/helpers';
import {
  InsertCommentOutlinedIcon,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  Stack,
  ToolTip,
  Typography
} from '@esgian/esgianui';
import { AddEditCommentModal } from '@components/Modals';
import { RigInfoDrawer } from '@components/Drawers';

function OperatorResourceButton({ resource }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showReset, setShowReset] = useState(false);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [resourceComment, setResourceComment] = useState(null);
  const {
    state: {
      projectsData: { projects }
    },
    dispatch
  } = useStore();
  const params = useParams();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCommentApplyClick = (comment) => {
    dispatch({
      type: GENERAL_ACTIONS.ADD_OR_UPDATE_COMMENT,
      flow: FLOW.GENERAL,
      payload: {
        projectId: parseInt(params.projectId),
        comment: comment,
        resourceId: resource.id
      }
    });
    setCommentModalOpen(false);
    snackbar({
      message: 'Comment saved',
      color: 'success',
      duration: 5000,
      display: 'top'
    });
  };

  useEffect(() => {
    const { comments } = getProject(projects, parseInt(params.projectId));
    setResourceComment(
      comments?.find(({ resourceId, eventId }) => eventId === null && resourceId === resource.id)
        ?.comment || null
    );
  }, [resource]);

  const handleRemoveClick = () => {
    dispatch({
      type: OIL_COMPANY_ACTIONS.REMOVE_OPERATOR_FROM_PROJECT,
      flow: FLOW.OIL_COMPANY,
      payload: {
        projectId: params.projectId,
        operatorId: resource.id
      }
    });
    snackbar({
      message: 'Rig removed',
      color: 'success',
      duration: 5000,
      display: 'top'
    });
  };

  return (
    <>
      <Stack direction={'row'} spacing={1}>
        <div style={{ cursor: 'pointer' }} onClick={handleClick} className="resource-name">
          {resource.name}
        </div>
        {resourceComment && (
          <ToolTip title={resourceComment}>
            <span style={{ fontSize: '1rem', color: 'rgba(0, 0, 0, 0.54)' }}>
              <InsertCommentOutlinedIcon color={'inherit'} fontSize={'inherit'} />
            </span>
          </ToolTip>
        )}
      </Stack>

      <Typography variant={'caption'} color={'text.secondary'}>
        {resource.rigType}
      </Typography>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <List>
          <ListItemButton
            onClick={() => {
              handleClose();
              handleRemoveClick();
            }}>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={`Remove`} />
          </ListItemButton>
          {showReset && (
            <ListItemButton
              onClick={() => {
                handleClose();
              }}>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={`Reset`} />
            </ListItemButton>
          )}
          <ListItemButton
            onClick={() => {
              handleClose();
              setCommentModalOpen(true);
            }}>
            <ListItemText
              primaryTypographyProps={{ variant: 'body2' }}
              primary={`${resourceComment ? 'Edit' : 'Add'} Comment`}
            />
          </ListItemButton>
        </List>
      </Menu>
      {commentModalOpen && (
        <AddEditCommentModal
          handleApplyClick={handleCommentApplyClick}
          open={commentModalOpen}
          comment={resourceComment}
          handleClose={() => setCommentModalOpen(false)}
        />
      )}
    </>
  );
}

OperatorResourceButton.propTypes = {
  resource: PropTypes.object
};

OperatorResourceButton.defaultProps = {
  resource: {}
};

export default OperatorResourceButton;

import { BaseApiRequest } from './BaseApi';

export const verifyToken = (signal, token) => {
  return BaseApiRequest(signal, `/profile?token=${token}`, 'auth');
};

export const registerUser = (signal, firstName, lastName, email, password, company) => {
  const data = {
    firstname: firstName,
    lastname: lastName,
    email: email,
    password: password,
    company: company,
    project: 'values',
    tokenType: 'web'
  };
  let urlParameters = new URLSearchParams(data).toString();

  return BaseApiRequest(signal, `/register?${urlParameters}`, 'auth', 'POST');
};

export const loginUser = (signal, username, password) => {
  const body = {
    username: username,
    password: password,
    project: 'values',
    tokenType: 'web'
  };
  return BaseApiRequest(signal, `/login`, 'auth', 'POST', body);
};

export const logoutUser = (token) => {
  return BaseApiRequest(null, `/logout?token=${token}`, 'auth', 'DELETE');
};

import moment from 'moment/moment';
import { getUserFirstAndLast, RIG_SUPPLIER_ACTIONS } from '@store/AppReducer';

export const getRigSupplierActions = (state, action) => {
  switch (action.type) {
    case RIG_SUPPLIER_ACTIONS.ADD_RIGS_TO_PROJECT: {
      const { rigs, projectId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const updateId = projectId;
      const foundIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(updateId)
      );
      if (foundIndex > -1) {
        projectsCopy[foundIndex].rigs = [...projectsCopy[foundIndex].rigs, ...rigs].sort((a, b) => {
          if (a.rigName > b.rigName) return 1;
          if (a.rigName < b.rigName) return -1;
          return 0;
        });
        projectsCopy[foundIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[foundIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case RIG_SUPPLIER_ACTIONS.REMOVE_RIG_FROM_PROJECT: {
      const { rigId, projectId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const updateId = projectId;
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(updateId)
      );
      if (projectIndex > -1) {
        projectsCopy[projectIndex].rigs = projectsCopy[projectIndex].rigs.filter(
          (rig) => rig.rigId !== rigId
        );
        projectsCopy[projectIndex].customContracts =
          projectsCopy[projectIndex].customContracts?.filter((item) => item.rigId !== rigId) || [];
        projectsCopy[projectIndex].deletedContracts =
          projectsCopy[projectIndex].deletedContracts?.filter((item) => item.rigId !== rigId) || [];
        projectsCopy[projectIndex].customRequirements =
          projectsCopy[projectIndex].customRequirements?.filter((item) => item.rigId !== rigId) ||
          [];
        projectsCopy[projectIndex].eligibleRigs = projectsCopy[projectIndex].eligibleRigs?.filter(
          ({ rigId: customRigId }) => customRigId !== rigId
        );
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case RIG_SUPPLIER_ACTIONS.RESET_RIG: {
      const { rigId, projectId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const updateId = projectId;
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(updateId)
      );
      if (projectIndex > -1) {
        const rigIndex = projectsCopy[projectIndex]?.rigs.findIndex(
          (rig) => parseInt(rig.rigId) === parseInt(rigId)
        );
        if (rigIndex > -1) {
          projectsCopy[projectIndex].rigs[rigIndex].comment = null;
        }
        projectsCopy[projectIndex].customContracts =
          projectsCopy[projectIndex].customContracts?.filter((item) => item.rigId !== rigId) || [];
        projectsCopy[projectIndex].deletedContracts =
          projectsCopy[projectIndex].deletedContracts?.filter((item) => item.rigId !== rigId) || [];
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case RIG_SUPPLIER_ACTIONS.ADD_RIG_COMMENT: {
      const { rigId, comment } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const updateId = action.payload.projectId;
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(updateId)
      );
      if (projectIndex > -1) {
        const updateRigIndex = projectsCopy[projectIndex]?.rigs.findIndex(
          (rig) => parseInt(rig.rigId) === parseInt(rigId)
        );
        if (updateRigIndex > -1) {
          projectsCopy[projectIndex].rigs[updateRigIndex].comment = comment;
          projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
          projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
          tempState.projectsData.projects = projectsCopy;
        }
      }
      return tempState;
    }
    case RIG_SUPPLIER_ACTIONS.UPDATE_RIG_DEMAND_FILTERS: {
      const { filters, projectId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        projectsCopy[projectIndex].filters.rigDemand = filters;
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case RIG_SUPPLIER_ACTIONS.REMOVE_CONTRACT_FROM_RIG: {
      const { contract, projectId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        if (!contract.isCustom) {
          projectsCopy[projectIndex].deletedContracts.push({
            contractId: contract.contractId,
            rigId: contract.rigId
          });
        } else {
          projectsCopy[projectIndex].customContracts =
            projectsCopy[projectIndex].customContracts?.filter(
              (item) => item.rigId !== contract.rigId || item.contractId !== contract.contractId
            ) || [];
        }
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case RIG_SUPPLIER_ACTIONS.UPDATE_RIG_CONTRACT: {
      const { contract, projectId, newFields } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        projectsCopy[projectIndex].customContracts =
          projectsCopy[projectIndex].customContracts?.filter(
            (item) => item.rigId !== contract.rigId || item.contractId !== contract.contractId
          ) || [];
        projectsCopy[projectIndex].deletedContracts.push({
          contractId: contract.contractId,
          rigId: contract.rigId
        });
        projectsCopy[projectIndex].customContracts.push({
          ...contract,
          ...newFields,
          ...{ isCustom: true }
        });
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case RIG_SUPPLIER_ACTIONS.RESET_CONTRACT: {
      const { contractId, projectId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        projectsCopy[projectIndex].customContracts =
          projectsCopy[projectIndex].customContracts?.filter(
            (item) => item.contractId !== contractId
          ) || [];
        projectsCopy[projectIndex].deletedContracts = projectsCopy[
          projectIndex
        ].deletedContracts.filter((item) => contractId !== item.contractId);
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case RIG_SUPPLIER_ACTIONS.REMOVE_ELIGIBLE_RIGS_FOR_REQUIREMENT: {
      const { projectId, rigId, requirementId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        projectsCopy[projectIndex].eligibleRigs = projectsCopy[projectIndex].eligibleRigs.filter(
          (item) =>
            parseInt(item.rigId) === parseInt(rigId) &&
            parseInt(requirementId) === parseInt(item.requirementId)
        );
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case RIG_SUPPLIER_ACTIONS.REMOVE_ELIGIBLE_RIG_FOR_REQUIREMENT: {
      const { projectId, parentRigId, requirementId, rigId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        const eligibleIndex = projectsCopy[projectIndex].eligibleRigs?.findIndex(
          (item) =>
            parseInt(item.rigId) === parseInt(parentRigId) &&
            parseInt(requirementId) === parseInt(item.requirementId)
        );

        projectsCopy[projectIndex].eligibleRigs[eligibleIndex].rigs = projectsCopy[
          projectIndex
        ].eligibleRigs[eligibleIndex]?.rigs?.filter((item) => item.rigId !== rigId);
        projectsCopy[projectIndex].eligibleRigs = projectsCopy[projectIndex].eligibleRigs?.filter(
          ({ rigs }) => rigs.length > 0
        );
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }

      return tempState;
    }
    case RIG_SUPPLIER_ACTIONS.UPDATE_ELIGIBLE_RIGS_FOR_REQUIREMENT: {
      const { projectId, rigId, operatorId, rigs, requirementId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        const rigIndex = projectsCopy[projectIndex].eligibleRigs.findIndex(
          (item) =>
            (parseInt(item.operatorId) === parseInt(operatorId) ||
              parseInt(item.rigId) === parseInt(rigId)) &&
            parseInt(requirementId) === parseInt(item.requirementId)
        );
        if (rigIndex > -1) {
          projectsCopy[projectIndex].eligibleRigs[rigIndex].rigs = rigs;
        } else {
          let resourceId = rigId ? { rigId: rigId } : { operatorId: operatorId };
          projectsCopy[projectIndex].eligibleRigs.push({
            ...resourceId,
            requirementId: requirementId,
            rigs: rigs
          });
        }
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case RIG_SUPPLIER_ACTIONS.CREATE_REQUIREMENT: {
      const { projectId, requirement } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        const project = projectsCopy[projectIndex];
        let contractId = 1;
        if (project?.customRequirements.length) {
          let contractIdMax = Math.max([
            ...project.customRequirements.map(({ contractId }) => contractId)
          ]);
          contractId = contractIdMax + 1;
        }

        project.customRequirements.push({
          ...requirement,
          ...{
            contractId: requirement?.contractId || contractId,
            isRequirement: true
          }
        });
        project.lastEditDate = moment().format('DD/MM/yyyy');
        project.lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects[projectIndex] = project;
      }
      return tempState;
    }
    case RIG_SUPPLIER_ACTIONS.UPDATE_REQUIREMENT: {
      const { projectId, requirement, newFields } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        const requirementIndex = projectsCopy[projectIndex].customRequirements.findIndex(
          ({ contractId, rigId }) =>
            contractId === requirement.contractId && rigId === requirement.rigId
        );
        if (newFields?.rigId && newFields?.rigId !== requirement.rigId) {
          tempState.projectsData.projects[projectIndex].eligibleRigs =
            tempState.projectsData.projects[projectIndex].eligibleRigs?.filter(
              (item) => (item.rigId = requirement.rigId)
            );
        }
        tempState.projectsData.projects[projectIndex].customRequirements[requirementIndex] = {
          ...requirement,
          ...newFields
        };
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case RIG_SUPPLIER_ACTIONS.REMOVE_REQUIREMENT: {
      const { projectId, requirementId, rigId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        projectsCopy[projectIndex].customRequirements = projectsCopy[
          projectIndex
        ].customRequirements?.filter(
          (item) => item.contractId !== requirementId || item.rigId !== rigId
        );
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case RIG_SUPPLIER_ACTIONS.ASSIGN_REQUIREMENT_TO_RIG: {
      const { projectId, requirement, rigId, rigName } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        projectsCopy[projectIndex].customRequirements = projectsCopy[
          projectIndex
        ].customRequirements?.filter(
          (item) => item.contractId !== requirement.contractId || item.rigId !== rigId
        );
        projectsCopy[projectIndex].eligibleRigs = projectsCopy[projectIndex].eligibleRigs?.filter(
          (item) => item.requirementId !== requirement.contractId || item.rigId !== rigId
        );
        projectsCopy[projectIndex].customContracts.push({
          ...requirement,
          ...{
            rigId: rigId,
            rigName: rigName,
            isRequirement: false,
            originalRequirement: true,
            isCustom: true
          }
        });
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case RIG_SUPPLIER_ACTIONS.RESET_REQUIREMENT: {
      const { projectId, requirementId, rigId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...state.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        const {
          filterLookups: { contractsWithoutRigs: lookupRequirements }
        } = tempState;
        let original = projectsCopy[projectIndex].customRequirements?.find(
          ({ contractId, rigId }) => contractId === requirementId && rigId === null
        );
        if (!original) {
          original = lookupRequirements?.find(
            (requirement) => requirement.contractId === requirementId
          );
        }
        if (original) {
          projectsCopy[projectIndex].customRequirements = projectsCopy[
            projectIndex
          ].customRequirements.filter(
            (req) => parseInt(req.contractId) !== parseInt(requirementId) || rigId !== req.rigId
          );
          projectsCopy[projectIndex].customRequirements.push({
            ...original,
            ...{
              rigId: rigId,
              isRequirement: true
            }
          });
        }
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }

    default:
      return state;
  }
};

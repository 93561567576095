import { EVENT_TYPES } from '@constants';
import moment from 'moment';

export const getRigDemandFilterDefaults = (lookups) => {
  const { waterDepth: lookupWaterDepth = [] } = lookups || {};

  return {
    regions: { type: 'multiselect', value: [] },
    countries: { type: 'multiselect', value: [] },
    rigTypes: { type: 'multiselect', value: [] },
    operators: { type: 'multiselect', value: [] },
    contractStatus: { type: 'multiselect', value: [] },
    waterDepth: { type: 'range', min: lookupWaterDepth[0], max: lookupWaterDepth[1] }
  };
};

export const filterToOperatorKey = {
  regions: 'regionId',
  countries: 'countryId',
  rigTypes: 'rigTypes',
  operators: 'operatorId',
  contractStatus: 'contractStatusId',
  waterDepth: 'waterDepth'
};

const getTimelineEvents = (requirements) => {
  return requirements?.map((item) => {
    let latestStartDate = item.latestStartDate || item.startDate;

    return {
      ...{
        details: {
          ...item,
          ...{
            latestStartDate: latestStartDate,
            isRequirement: true,
            type: EVENT_TYPES.REQUIREMENT
          }
        },
        start: moment(item.startDate).format('yyyy-MM-DD'),
        end: moment(latestStartDate).add(item.duration, 'days').format('yyyy-MM-DD'),
        resource: item.operatorId,
        title: item.projectName || null,
        type: EVENT_TYPES.REQUIREMENT,
        taskType: item.contractStatusId
      }
    };
  });
};

const getFilteredRequirements = (filterLookups, filters, requirements, currentDate, calView) => {
  const defaultFilters = getRigDemandFilterDefaults(filterLookups);
  let includeRigs = [];
  requirements?.forEach((requirement) => {
    let include = true;
    if (moment(requirement.endDate).isBefore(currentDate)) {
      include = false;
    }
    Object.entries(filters).forEach(([key, filter]) => {
      if (filter.type === 'multiselect' && filter.value?.length) {
        let filterIds = [...filter.value].map(({ id }) => id);
        if (Array.isArray(requirement[filterToOperatorKey[key]])) {
          if (!requirement[filterToOperatorKey[key]].some(({ id }) => filterIds.includes(id))) {
            include = false;
          }
        } else if (!filterIds.includes(requirement[filterToOperatorKey[key]])) {
          include = false;
        }
      }
      if (filter.type === 'range') {
        if (JSON.stringify(defaultFilters[key]) !== JSON.stringify(filter)) {
          if (defaultFilters[key].min === filter.min) {
            if (
              requirement[filterToOperatorKey[key]] !== null &&
              requirement[filterToOperatorKey[key]] > filter.max
            ) {
              include = false;
            }
          } else {
            if (
              requirement[filterToOperatorKey[key]] > filter.max ||
              requirement[filterToOperatorKey[key]] < filter.min
            ) {
              include = false;
            }
          }
        }
      }
    });
    if (include) {
      includeRigs.push(requirement);
    }
  });
  return includeRigs;
};

export const getRigDemandTimelineData = (customRequirements = [], filterLookups, filters) => {
  const { contractsWithoutRigs, rigsOperators: lookupOperators } = filterLookups;
  const requirements = getFilteredRequirements(filterLookups, filters, [
    ...contractsWithoutRigs,
    ...[...(customRequirements?.filter(({ rigId }) => rigId === null || rigId === undefined) || [])]
  ]);

  let operatorContractIds = [...new Set(requirements.map(({ operatorId }) => operatorId))];
  const resource = lookupOperators
    .filter(({ id }) => operatorContractIds.includes(id))
    ?.map((item) => {
      return { ...item, ...{ color: '#595959' } };
    });
  const events = getTimelineEvents(requirements);
  return { resource, events };
};

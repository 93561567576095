import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  ChecklistIcon,
  CloseIcon,
  DeleteOutlineIcon,
  Divider,
  Grid,
  IconButton,
  PlusIcon,
  ResetIcon,
  Stack,
  Typography
} from '@esgian/esgianui';
import { TimelineLegend } from '@components';
import { RigsChartSettingButton } from '@components/Sections/RigSupplierSection/Menues';
import RigDemandTimeline from '@components/Sections/OilCompanySection/RigDemandTimeline';
import AddRequirementDrawer from '../Drawers/AddRequirementDrawer';
import { AddEditRequirementModal } from '@components/Modals';
import { useStore } from '@store/Store';
import { FLOW, GENERAL_ACTIONS, OIL_COMPANY_ACTIONS } from '@store/AppReducer';
import { useParams } from 'react-router-dom';
import SelectedTimelineResourcesDisplay from '../../../Display/SelectedTimelineResourcesDisplay';
import Swal from 'sweetalert2';
import { snackbar } from '@mobiscroll/react';
import DemandTimelineTwo from '@components/Sections/OilCompanySection/RigDemandTimeline/DemandTimelineTwo';

function RigDemandTimelineContainer({ project }) {
  const [showStatus, setShowStatus] = useState(false);
  const [requirementModalOpen, setRequirementModalOpen] = useState(false);
  const [showSelectResource, setShowSelectResource] = useState(false);
  const [selectedResources, setSelectedResources] = useState([]);
  const [editRequirement, setEditRequirement] = useState(null);
  const [addRequirementOpen, setAddRequirementOpen] = useState(false);
  const {
    state: {
      projectsData: { projects }
    },
    dispatch
  } = useStore();
  const params = useParams();

  useEffect(() => {
    if (!showSelectResource) {
      setSelectedResources([]);
    }
  }, [showSelectResource]);

  const handleDeleteRowsClick = useCallback(() => {
    if (!selectedResources?.length) return;
    Swal.fire({
      title: 'Are you sure?',
      text: `This action can not be reverted`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        selectedResources.forEach((resource) => {
          if (typeof resource === 'number') {
            dispatch({
              type: OIL_COMPANY_ACTIONS.REMOVE_OPERATOR_FROM_PROJECT,
              flow: FLOW.OIL_COMPANY,
              payload: { projectId: params.projectId, operatorId: resource }
            });
          } else {
            let ids = resource.split(',');
            if (ids?.length === 2) {
              if (ids[0] === 'rig') {
                dispatch({
                  type: OIL_COMPANY_ACTIONS.RESET_CUSTOM_RIG,
                  flow: FLOW.OIL_COMPANY,
                  payload: {
                    projectId: params.projectId,
                    resourceId: `rig,${ids[1]}`,
                    rigId: parseInt(ids[1])
                  }
                });
              } else {
                dispatch({
                  type: OIL_COMPANY_ACTIONS.REMOVE_REQUIREMENT,
                  flow: FLOW.OIL_COMPANY,
                  payload: {
                    projectId: params.projectId,
                    operatorId: parseInt(ids[0]),
                    requirementId: parseInt(ids[1])
                  }
                });
              }
            }
            if (ids?.length === 3) {
              dispatch({
                type: OIL_COMPANY_ACTIONS.REMOVE_ELIGIBLE_RIG_FOR_REQUIREMENT,
                flow: FLOW.OIL_COMPANY,
                payload: {
                  projectId: params.projectId,
                  operatorId: parseInt(ids[0]),
                  requirementId: parseInt(ids[1]),
                  rigId: parseInt(ids[2])
                }
              });
            }
          }
        });
        setSelectedResources([]);
        setShowSelectResource(false);
        snackbar({
          message: `Remove success`,
          color: 'success',
          duration: 5000,
          display: 'top'
        });
      }
    });
  }, [selectedResources, JSON.stringify(projects)]);

  const handleResetRowsClick = useCallback(() => {
    if (!selectedResources?.length) return;
    Swal.fire({
      title: 'Are you sure?',
      text: `This action can not be reverted`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        selectedResources.forEach((resource) => {
          if (typeof resource === 'number') {
            dispatch({
              type: OIL_COMPANY_ACTIONS.RESET_OPERATOR,
              flow: FLOW.OIL_COMPANY,
              payload: { projectId: params.projectId, operatorId: resource }
            });
          } else {
            let ids = resource.split(',');
            if (ids?.length === 2) {
              if (ids[0] === 'rig') {
                dispatch({
                  type: OIL_COMPANY_ACTIONS.RESET_CUSTOM_RIG,
                  flow: FLOW.OIL_COMPANY,
                  payload: {
                    projectId: params.projectId,
                    resourceId: `rig,${ids[1]}`,
                    rigId: parseInt(ids[1])
                  }
                });
              } else {
                dispatch({
                  type: OIL_COMPANY_ACTIONS.RESET_REQUIREMENT,
                  flow: FLOW.OIL_COMPANY,
                  payload: { projectId: params.projectId, requirementId: parseInt(ids[1]) }
                });
              }
            }
            if (ids?.length === 3) {
              dispatch({
                type: GENERAL_ACTIONS.REMOVE_COMMENT,
                flow: FLOW.GENERAL,
                payload: { projectId: params.projectId, resourceId: resource }
              });
            }
          }
        });
        setSelectedResources([]);
        setShowSelectResource(false);
        snackbar({
          message: `Reset success`,
          color: 'success',
          duration: 5000,
          display: 'top'
        });
      }
    });
  }, [selectedResources, JSON.stringify(projects)]);

  return (
    <>
      <Grid container spacing={2}>
        <TimelineLegend setShowStatus={setShowStatus} showStatus={showStatus} />
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Typography bold>Rig Demand</Typography>
              <Typography variant={'body2'}>
                Displaying {project?.operators?.length || 0} operators
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}
              spacing={2}>
              <Stack direction={'row'} spacing={2}>
                <Button
                  variant={'text'}
                  key={'create-requirement'}
                  onClick={() => setRequirementModalOpen(true)}
                  startIcon={<PlusIcon color={'inherit'} />}>
                  Create requirement
                </Button>
                <Button
                  variant={'text'}
                  key={'add-requirement'}
                  onClick={() => setAddRequirementOpen(true)}
                  startIcon={<PlusIcon color={'inherit'} />}>
                  add requirements
                </Button>
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <Button
                  variant={'text'}
                  color={'secondary'}
                  key={'select-row-button'}
                  onClick={() => setShowSelectResource(!showSelectResource)}
                  startIcon={<ChecklistIcon fontSize={'25px !important'} color={'inherit'} />}>
                  Select
                </Button>
                <RigsChartSettingButton />
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2}>
            {showSelectResource && (
              <SelectedTimelineResourcesDisplay
                showSelectResource={showSelectResource}
                setSelectedResources={setSelectedResources}
                setShowSelectResource={setShowSelectResource}
                selectedResources={selectedResources}
                handleDeleteRowsClick={handleDeleteRowsClick}
                handleResetRowsClick={handleResetRowsClick}
              />
            )}
            <RigDemandTimeline
              showSelectResource={showSelectResource}
              selectedResources={selectedResources}
              setSelectedResources={setSelectedResources}
              showStatus={showStatus}
              setRequirementModalOpen={setRequirementModalOpen}
              setAddRequirementOpen={setAddRequirementOpen}
            />
          </Stack>
        </Grid>
      </Grid>
      <AddRequirementDrawer setOpen={setAddRequirementOpen} open={addRequirementOpen} />
      <AddEditRequirementModal
        flow={FLOW.OIL_COMPANY}
        open={requirementModalOpen}
        requirement={editRequirement}
        handleClose={() => {
          setRequirementModalOpen(false);
          setEditRequirement(null);
        }}
      />
    </>
  );
}

RigDemandTimelineContainer.propTypes = {
  project: PropTypes.object.isRequired
};

RigDemandTimelineContainer.defaultProps = {};

export default RigDemandTimelineContainer;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Datepicker, localeEn } from '@mobiscroll/react';
import moment from 'moment';
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@esgian/esgianui';

function TimelineCustomHeader({
  calView,
  setCalView,
  setCurrentDate,
  setRefDate,
  startDate,
  endDate,
  rangeVal,
  buttonText,
  setRangeVal
}) {
  const buttonProps = React.useMemo(() => {
    const content = <span className="mbsc-calendar-title">{buttonText}</span>;

    return {
      children: content,
      className: 'mbsc-calendar-button',
      variant: 'flat'
    };
  }, [buttonText]);

  const getNrDays = useCallback((start, end, resolution) => {
    let startDate = moment(start);
    let endDate = moment(end);
    let nrMonths = endDate.diff(startDate, 'months');
    if (resolution === 'year') {
      nrMonths = endDate.diff(startDate, 'years');
    }
    return nrMonths + 1;
  }, []);

  const onClose = React.useCallback(() => {
    if (startDate.current && endDate.current) {
      // navigate the calendar
      setCurrentDate(startDate.current);
      // set calendar view
      setRefDate(startDate.current);
      setCalView({
        timeline: {
          ...calView.timeline,
          ...{ size: getNrDays(startDate.current, endDate.current, calView.timeline.resolution) }
        }
      });
    }
    setRangeVal([startDate.current, endDate.current]);
  }, [getNrDays, calView]);

  const onChange = React.useCallback((args) => {
    const date = args.value;
    setRangeVal(date);
    if (date[0] && date[1]) {
      startDate.current = date[0];
      endDate.current = date[1];
    }
  }, []);

  const handleResolutionChange = useCallback(
    ({ target }) => {
      if (startDate.current && endDate.current) {
        startDate.current = startDate.current
          ? moment(startDate.current).startOf('year')
          : moment().startOf('year');
        endDate.current = moment(endDate.current).endOf('year');
        // navigate the calendar
        setCurrentDate(startDate.current);
        // set calendar view
        setRefDate(startDate.current);

        let tempCallView = { ...calView };
        tempCallView.timeline.resolution = target.value;
        tempCallView.timeline.type = target.value;
        tempCallView.timeline.size = getNrDays(
          startDate.current,
          endDate.current,
          calView.timeline.resolution
        );
        setCalView(tempCallView);
      }
      setRangeVal([startDate.current, endDate.current]);
    },
    [getNrDays, calView.timeline.resolution, startDate, endDate]
  );

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      sx={{ backgroundColor: '#f5f6f7', height: '4em', width: '100%' }}>
      <Datepicker
        dateWheels={calView.timeline.resolution === 'year' ? 'YYYY' : 'MMM YYYY'}
        dateFormat={calView.timeline.resolution === 'year' ? 'YYYY' : 'MMM YYYY'}
        rangeStartLabel={`Start ${calView.timeline.resolution}`}
        rangeEndLabel={`End ${calView.timeline.resolution}`}
        locale={localeEn}
        returnFormat={'moment'}
        select="range"
        display="anchored"
        showOverlay={false}
        showOuterDays={false}
        touchUi={true}
        buttons={[]}
        inputComponent={Button}
        inputProps={buttonProps}
        onClose={onClose}
        onChange={onChange}
        value={rangeVal}
      />
      <div>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel>View</InputLabel>
          <Select
            value={calView.timeline.resolution}
            label="View"
            onChange={handleResolutionChange}>
            <MenuItem value={'month'}>Month</MenuItem>
            <MenuItem value={'year'}>Year</MenuItem>
          </Select>
        </FormControl>
      </div>
    </Stack>
  );
}

TimelineCustomHeader.propTypes = {
  calView: PropTypes.object.isRequired,
  setCalView: PropTypes.func.isRequired,
  setCurrentDate: PropTypes.func.isRequired,
  setRefDate: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  rangeVal: PropTypes.arrayOf(PropTypes.object),
  setRangeVal: PropTypes.func.isRequired
};

TimelineCustomHeader.defaultProps = {};

export default TimelineCustomHeader;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Eventcalendar, localeEn, setOptions, snackbar, toast } from '@mobiscroll/react';
import moment from 'moment';
import { useStore } from '@store/Store';
import { EVENT_TYPES, MONTH_SHORTNAMES } from '@constants';
import TimelineCustomHeader from '@components/Timeline/TimelineCustomHeader';
import {
  Button,
  Checkbox,
  CircularProgress,
  Paper,
  PlusIcon,
  Stack,
  SubdirectoryArrowRightIcon,
  Typography
} from '@esgian/esgianui';
import { getProject } from '@helpers/helpers';
import { checkAndAcceptUpdate, getRigSupplyData } from '@helpers/rigSupplierHelpers';
import { useParams } from 'react-router-dom';
import { FLOW, RIG_SUPPLIER_ACTIONS } from '@store/AppReducer';
import {
  EventMismatchModal,
  AddRigCommentModal
} from '@components/Sections/RigSupplierSection/Modules';

import { EventDetailsDrawer } from '@components/Drawers';
import { ActivityEvent, RequirementEvent } from '@components/Timeline/EventTypes';
import ContractEvent from '../../../Timeline/EventTypes/ContractEvent';
import RequirementResourceButton from '../../../Timeline/ResourceButtons/RequirementResourceButton';
import EligibleRigResourceButton from '../../../Timeline/ResourceButtons/EligibleRigResourceButton';
import RequirementMenu from '../../../Timeline/RequirementMenu';
import { ContractMenu, RigResourceButton } from '@components/Timeline';
import { getUpdatedRequirementDrag } from '@helpers/timelineHelpers';

setOptions({
  locale: localeEn,
  theme: 'ios',
  themeVariant: 'light'
});

function RigSupplyTimeline({
  showSelectResource,
  setSelectedResources,
  selectedResources,
  showStatus,
  addRigsButtonClick,
  hideRequirements,
  project,
  calView,
  currentDate,
  height,
  setHeight,
  refDate,
  setRefDate,
  setCalView,
  setCurrentDate
}) {
  const [updateRigComment, setUpdateRigComment] = useState(null);
  const [eventMismatch, setEventMismatch] = useState(null);
  const [loadingTimeline, setLoadingTimeline] = useState(true);
  const [rigsContracts, setRigsContracts] = useState([]);
  const [eventMenuOpen, setEventMenuOpen] = useState({ requirement: false, contract: false });
  const [eventMenuItem, setEventMenuItem] = useState(null);
  const [mousePosition, setMousePosition] = useState(null);
  const [showContractDetails, setShowContractDetails] = useState(null);
  const [resources, setResources] = useState([]);
  const [childResourceIds, setChildResourceIds] = useState([]);
  const [buttonText, setButtonText] = useState('');
  const params = useParams();
  const startDate = useRef(moment().startOf('year'));
  const endDate = useRef(
    moment()
      .add(calView.timeline.size - 1, calView.timeline.resolution)
      .endOf(calView.timeline.resolution)
  );
  const [rangeVal, setRangeVal] = useState([startDate.current, endDate.current]);

  const {
    state: {
      themeMode,
      filterLookups,
      sectionFilters: {
        rigSupply: { chartSettings }
      },
      projectsData: { projects }
    },
    dispatch
  } = useStore();

  const { rigs: lookupRigs } = filterLookups;

  useEffect(() => {
    if (!resources?.length) return;
    if (hideRequirements) {
      setHeight(90);
    } else {
      setHeight(45);
    }
    // Max height is 90 vh because drawer is 10vh
  }, [hideRequirements]);

  useEffect(() => {
    if (!projects?.length || !lookupRigs?.length) return;
    const projectId = params.projectId;
    const {
      rigs: selectedRigs,
      customContracts,
      deletedContracts,
      customRequirements
    } = getProject(projects, projectId);

    const { events, resources, childResourceEventIds } = getRigSupplyData(
      selectedRigs,
      customContracts,
      deletedContracts,
      customRequirements,
      [...lookupRigs]
    );

    setChildResourceIds(childResourceEventIds);
    setRigsContracts(events);
    setResources(resources);
    setLoadingTimeline(false);
  }, [JSON.stringify(projects), lookupRigs]);

  const myScheduleEvent = React.useCallback(
    (event) => {
      if (event.original.type === EVENT_TYPES.ACTIVITY) {
        return (
          <ActivityEvent
            flow={FLOW.RIG_SUPPLIER}
            event={event}
            chartSettings={chartSettings}
            showStatus={showStatus}
          />
        );
      }
      if (event.original.type === EVENT_TYPES.CONTRACT) {
        return (
          <ContractEvent
            flow={FLOW.RIG_SUPPLIER}
            event={event}
            chartSettings={chartSettings}
            showStatus={showStatus}
          />
        );
      }
      return (
        <RequirementEvent
          flow={FLOW.RIG_SUPPLIER}
          event={event}
          chartSettings={chartSettings}
          showStatus={showStatus}
        />
      );
    },
    [showStatus, chartSettings]
  );

  const handleResourceSelect = useCallback((resource, selectedResources) => {
    if (selectedResources.includes(resource.id)) {
      setSelectedResources([...selectedResources].filter((item) => item !== resource.id));
    } else {
      setSelectedResources([...selectedResources, resource.id]);
    }
  }, []);

  const getResourceButtons = useCallback((resource) => {
    return (
      <Stack>
        {resource.level === 1 && <RigResourceButton resource={resource} />}
        {resource.level === 2 && (
          <RequirementResourceButton flow={FLOW.RIG_SUPPLIER} resource={resource} />
        )}
        {resource.level === 3 && (
          <EligibleRigResourceButton flow={FLOW.RIG_SUPPLIER} resource={resource} />
        )}
      </Stack>
    );
  }, []);

  const renderCustomResource = useCallback(
    (resource) => {
      let paddingLeft = '0.75em';

      if (resource.level === 2 && !resource?.children?.length) {
        paddingLeft = '2.5em';
      }

      if (resource.level === 3) {
        paddingLeft = '4.5em';
      }
      let showArrow = resource.level === 3 || (resource.level === 2 && !resource.children?.length);
      return (
        <div
          className="resource-template-content"
          style={{
            background: resource.level > 1 ? '#f3f3f4' : 'transparent',
            borderRight: '1px solid lightgray',
            // borderTop: resource.level === 1 ? '1px solid lightgray' : '',
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Stack>
            <Stack
              alignItems={'center'}
              sx={{ paddingLeft: `${paddingLeft}` }}
              direction={'row'}
              spacing={1}>
              {showArrow && <SubdirectoryArrowRightIcon />}
              {showSelectResource && (
                <Checkbox
                  sx={{ marginLeft: '0 !important' }}
                  onChange={() => handleResourceSelect(resource, selectedResources)}
                  checked={selectedResources.includes(resource.id)}
                  size="small"
                />
              )}
              {getResourceButtons(resource)}
            </Stack>
          </Stack>
        </div>
      );
    },
    [selectedResources, showSelectResource]
  );

  const onEventUpdate = React.useCallback(
    ({ event }) => {
      let message = 'Contract updated';
      const contract = { ...event.details };
      if (event.type === EVENT_TYPES.CONTRACT) {
        let duration = moment(event.end).diff(event.start, 'days');
        dispatch({
          type: RIG_SUPPLIER_ACTIONS.UPDATE_RIG_CONTRACT,
          flow: FLOW.RIG_SUPPLIER,
          payload: {
            newFields: { duration: duration, startDate: event.start, endDate: event.end },
            contract: contract,
            projectId: parseInt(params.projectId)
          }
        });
      }
      if (event.type === EVENT_TYPES.REQUIREMENT) {
        message = 'Requirement updated';
        let { start, end, details } = event;
        dispatch({
          type: RIG_SUPPLIER_ACTIONS.UPDATE_REQUIREMENT,
          flow: FLOW.RIG_SUPPLIER,
          payload: {
            projectId: parseInt(params.projectId),
            requirement: contract,
            newFields: { ...getUpdatedRequirementDrag(start, end, details), ...{ isCustom: true } }
          }
        });
      }
      snackbar({
        message: message,
        color: 'success',
        duration: 5000,
        display: 'top'
      });

      return false;
    },
    [rigsContracts]
  );

  const createEvent = useCallback(
    (event) => {
      const contract = { ...event.details };
      const resourceChanged = event.resource !== event.prevResource;
      if (event.type === EVENT_TYPES.REQUIREMENT) {
        if (resourceChanged) {
          if (typeof event.prevResource === 'string') {
            let oldRigId = event.prevResource?.split(',');
            dispatch({
              type: RIG_SUPPLIER_ACTIONS.REMOVE_REQUIREMENT,
              flow: FLOW.RIG_SUPPLIER,
              payload: {
                requirementId: parseInt(oldRigId[0]),
                rigId: parseInt(oldRigId[1]),
                projectId: parseInt(params.projectId)
              }
            });
          }

          dispatch({
            type: RIG_SUPPLIER_ACTIONS.CREATE_REQUIREMENT,
            flow: FLOW.RIG_SUPPLIER,
            payload: {
              requirement: { ...contract, rigId: event.resource },
              projectId: parseInt(params.projectId)
            }
          });
        } else {
          let { start, end } = event;
          dispatch({
            type: RIG_SUPPLIER_ACTIONS.UPDATE_REQUIREMENT,
            flow: FLOW.RIG_SUPPLIER,
            payload: {
              projectId: parseInt(params.projectId),
              requirement: contract,
              newFields: {
                isCustom: true,
                startDate: moment(start).format('yyyy-MM-DD'),
                latestStartDate: moment(end)
                  .subtract(contract.duration, 'days')
                  .format('yyyy-MM-DD')
              }
            }
          });
        }
      } else {
        let newFields = {
          newFields: { rigId: event.resource }
        };
        if (!resourceChanged) {
          newFields = {
            startDate: moment(event.start).format('yyyy-MM-DD'),
            endDate: moment(event.end).format('yyyy-MM-DD')
          };
        }
        dispatch({
          type: RIG_SUPPLIER_ACTIONS.UPDATE_RIG_CONTRACT,
          flow: FLOW.RIG_SUPPLIER,
          payload: {
            newFields: newFields,
            contract: contract,
            projectId: parseInt(params.projectId)
          }
        });
      }
      setEventMismatch(null);
    },
    [params.projectId]
  );

  const onEventCreate = useCallback(
    ({ event, action }) => {
      if (action === 'externalDrop' && !!resources.length) {
        const projectId = params.projectId;

        if (typeof event.resource === 'string') {
          let ids = event.resource.split(',');
          const resourceChanged = event.resource !== event.prevResource;
          if (ids.length === 2 && resourceChanged) {
            toast({
              message: 'Requirements can only be added to rigs.'
            });
            return false;
          }
          if (ids.length === 3) {
            toast({
              message: 'Requirements can not be added to eligible rigs'
            });
            return false;
          }
        }
        const { rigs, customRequirements } = getProject(projects, projectId);
        const requirementExist = !!customRequirements?.find(
          ({ rigId, contractId }) =>
            rigId === event.resource && contractId === event?.details?.contractId
        );
        if (event?.details?.isRequirement && requirementExist) {
          toast({
            message: 'This requirement already exist for this rig'
          });
          return false;
        }
        if (checkAndAcceptUpdate(event, rigs)) {
          createEvent(event);
        } else {
          setEventMismatch(event);
        }

        return false;
      }
    },
    [rigsContracts]
  );

  const onEventClick = useCallback(({ event, domEvent }) => {
    setEventMenuItem(event);
    setMousePosition({ x: domEvent.clientX, y: domEvent.clientY + 20 });
    if (event.type === EVENT_TYPES.CONTRACT) {
      setEventMenuOpen({ ...eventMenuOpen, contract: true });
    } else if (event.type === EVENT_TYPES.REQUIREMENT) {
      setEventMenuOpen({ ...eventMenuOpen, requirement: true });
    }
  }, []);

  const timelineCustomHeader = () => {
    return (
      <TimelineCustomHeader
        setRangeVal={setRangeVal}
        rangeVal={rangeVal}
        startDate={startDate}
        endDate={endDate}
        setCalView={setCalView}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        refDate={refDate}
        setRefDate={setRefDate}
        buttonText={buttonText}
        setButtonText={setButtonText}
        calView={calView}
      />
    );
  };
  // returns the formatted date
  const getFormattedRange = React.useCallback(
    (start, end) => {
      if (calView.timeline.resolution === 'year') {
        return `${moment(start).year()} - ${moment(end).year()}`;
      } else {
        return `${moment(start).format('MMM, yyyy')} - ${moment(end).format('MMM, yyyy')}`;
      }
    },
    [calView]
  );

  const onPageLoaded = React.useCallback(
    (args) => {
      const sDate = args.firstDay;
      const end = args.lastDay;
      const eDate = new Date(end.getFullYear(), end.getMonth(), end.getDate() - 1, 0);
      startDate.current = sDate;
      endDate.current = eDate;
      setTimeout(() => {
        // set button text
        setButtonText(getFormattedRange(sDate, eDate));
        // set range value
        setRangeVal([sDate, eDate]);
        // navigate the calendar
        setCurrentDate(sDate);
      });
    },
    [getFormattedRange]
  );

  const onSelectedDateChange = React.useCallback(
    (event) => {
      setCurrentDate(event.date);
    },
    [setCurrentDate]
  );
  const childResourceColors = useMemo(() => {
    return childResourceIds?.map((id) => {
      return {
        start: moment().subtract(100, 'year').format('yyyy-MM-DD'),
        end: moment().add(100, 'years').format('yyyy-MM-DD'),
        resource: id,
        background: '#f3f3f4'
      };
    });
  }, [childResourceIds]);

  const noRigs = !resources?.length;

  const timelineHeight = useMemo(() => {
    let numResources = 0;
    resources?.forEach(({ children }) => {
      children.forEach(() => {
        numResources++;
      });
      numResources++;
    });

    let fields = Object.values(chartSettings).reduce((a, item) => a + item, 0) + 1;
    let fieldHeight = fields * 32;
    return 100 + fieldHeight * numResources;
  }, [resources, chartSettings]);

  return (
    <Stack spacing={2} sx={{ mb: '2.5em' }}>
      <Paper
        variant={'outlined'}
        sx={{
          height: `calc(${height} + 2px)`,
          transition: 'height 250ms linear 0s'
        }}>
        <Stack spacing={2}>
          <div
            style={{
              maxHeight: '75vh',
              height: `${timelineHeight}px`,
              overflow: 'auto'
            }}
            id={'rig-supply-timeline'}>
            <Eventcalendar
              onPageLoaded={onPageLoaded}
              onSelectedDateChange={onSelectedDateChange}
              colors={childResourceColors}
              onEventUpdate={onEventUpdate}
              className={noRigs ? 'hiddenTimeline' : ''}
              onEventCreate={onEventCreate}
              monthNames={MONTH_SHORTNAMES}
              themeVariant={'light'}
              showEventTooltip={false}
              onEventClick={onEventClick}
              locale={localeEn}
              data={rigsContracts}
              externalDrop={!!resources?.length}
              dragToResize={true}
              renderScheduleEvent={myScheduleEvent}
              renderResource={renderCustomResource}
              resources={resources?.length ? resources : null}
              view={calView}
              renderHeader={timelineCustomHeader}
              selectedDate={currentDate}
              refDate={refDate}
            />
          </div>
          {loadingTimeline && (
            <Stack sx={{ p: 2 }} spacing={2} alignItems={'center'}>
              <CircularProgress size={'5em'} />
            </Stack>
          )}
          {noRigs && !loadingTimeline && (
            <Stack sx={{ p: 2 }} spacing={2} alignItems={'center'}>
              <Typography variant={'h6'}>Add rigs to start rig supply analysis</Typography>
              <Button
                variant={'outlined'}
                onClick={addRigsButtonClick}
                startIcon={<PlusIcon color={'inherit'} />}>
                Add rigs
              </Button>
            </Stack>
          )}
        </Stack>
      </Paper>
      <RequirementMenu
        flow={FLOW.RIG_SUPPLIER}
        mousePosition={mousePosition}
        eventMenuOpen={eventMenuOpen.requirement}
        handleClose={() => setEventMenuOpen({ ...eventMenuOpen, requirement: false })}
        eventMenuItem={eventMenuItem}
      />
      <ContractMenu
        flow={FLOW.RIG_SUPPLIER}
        mousePosition={mousePosition}
        eventMenuOpen={eventMenuOpen.contract}
        handleClose={() => setEventMenuOpen({ ...eventMenuOpen, contract: false })}
        eventMenuItem={eventMenuItem}
      />
      <EventMismatchModal
        event={eventMismatch}
        handleAccept={() => createEvent(eventMismatch)}
        onClose={() => setEventMismatch(null)}
      />
      <AddRigCommentModal
        project={project}
        selectedResource={updateRigComment}
        setRigComment={setUpdateRigComment}
      />
      <EventDetailsDrawer
        open={!!showContractDetails}
        event={showContractDetails}
        handleClose={() => setShowContractDetails(null)}
      />
    </Stack>
  );
}

RigSupplyTimeline.propTypes = {
  setViewRigInfo: PropTypes.func.isRequired,
  setRemoveRig: PropTypes.func.isRequired,
  addRigsButtonClick: PropTypes.func.isRequired,
  showStatus: PropTypes.bool,
  hideRequirements: PropTypes.bool,
  project: PropTypes.object.isRequired,
  calView: PropTypes.object,
  currentDate: PropTypes.object,
  height: PropTypes.number,
  setHeight: PropTypes.func.isRequired,
  refDate: PropTypes.object,
  setRefDate: PropTypes.func.isRequired,
  setCalView: PropTypes.func.isRequired,
  setCurrentDate: PropTypes.func.isRequired,
  showSelectResource: PropTypes.bool,
  selectedResources: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  setSelectedResources: PropTypes.func.isRequired,
  setShowSelectResource: PropTypes.func.isRequired
};

RigSupplyTimeline.defaultProps = {
  showStatus: false,
  calView: null,
  hideRequirements: false
};

export default RigSupplyTimeline;

import React from 'react';
import PropTypes from 'prop-types';
import { DeleteModal, Grid, Stack, Typography } from '@esgian/esgianui';
import { ACTIONS, FLOW, GENERAL_ACTIONS } from '@store/AppReducer';
import { useStore } from '@store/Store';

function DeleteProjectModal({ deleteProject, setDeleteProject }) {
  const { dispatch } = useStore();

  const handleDeleteClick = () => {
    dispatch({
      type: GENERAL_ACTIONS.DELETE_PROJECT,
      flow: FLOW.GENERAL,
      payload: { projectId: deleteProject }
    });
    setDeleteProject(null);
  };

  return (
    <DeleteModal
      open={!!deleteProject}
      onClose={() => setDeleteProject(null)}
      handleDeleteClick={handleDeleteClick}>
      <Grid container>
        <Grid item xs={12}>
          <Stack spacing={2} alignItems={'center'}>
            <Typography variant={'h4'}>Are you sure?</Typography>
            <Typography variant={'body2'}>This action can not be undone</Typography>
          </Stack>
        </Grid>
      </Grid>
    </DeleteModal>
  );
}

DeleteProjectModal.propTypes = {
  deleteProject: PropTypes.number,
  setDeleteProject: PropTypes.func.isRequired
};

DeleteProjectModal.defaultProps = {
  deleteProject: null
};

export default DeleteProjectModal;

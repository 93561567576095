import moment from 'moment/moment';
import { getUserFirstAndLast, OIL_COMPANY_ACTIONS, RIG_SUPPLIER_ACTIONS } from '@store/AppReducer';

export const getOilCompanyActions = (state, action) => {
  switch (action.type) {
    case OIL_COMPANY_ACTIONS.ADD_OPERATORS_TO_PROJECT: {
      const { projectId, operators } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        projectsCopy[projectIndex].operators = [
          ...projectsCopy[projectIndex].operators,
          ...operators
        ];
        projectsCopy[projectIndex].operators = projectsCopy[projectIndex].operators.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }

      return tempState;
    }
    case OIL_COMPANY_ACTIONS.REMOVE_OPERATOR_FROM_PROJECT: {
      const { projectId, operatorId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        projectsCopy[projectIndex].operators = projectsCopy[projectIndex].operators?.filter(
          (opr) => opr.id !== operatorId
        );

        projectsCopy[projectIndex].eligibleRigs = projectsCopy[projectIndex].eligibleRigs?.filter(
          (elig) => elig.operatorId !== operatorId
        );

        projectsCopy[projectIndex].deletedRequirements = projectsCopy[
          projectIndex
        ].deletedRequirements?.filter((requirement) => requirement.operatorId !== operatorId);

        projectsCopy[projectIndex].customRequirements = projectsCopy[
          projectIndex
        ].customRequirements?.filter((requirement) => requirement.operatorId !== operatorId);
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }

      return tempState;
    }
    case OIL_COMPANY_ACTIONS.REMOVE_ELIGIBLE_RIG_FOR_REQUIREMENT: {
      const { projectId, operatorId, requirementId, rigId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        projectsCopy[projectIndex].eligibleRigs = projectsCopy[projectIndex].eligibleRigs?.map(
          (elig) => {
            if (elig.operatorId === operatorId && elig.requirementId === requirementId) {
              let obj = { ...elig };
              obj.rigs = obj.rigs.filter((rig) => rig.rigId !== rigId);
              return obj;
            } else {
              return elig;
            }
          }
        );

        projectsCopy[projectIndex].eligibleRigs = projectsCopy[projectIndex].eligibleRigs?.filter(
          (elig) => elig.rigs?.length > 0
        );
        projectsCopy[projectIndex].comments = projectsCopy[projectIndex].comments?.filter(
          ({ resourceId }) => resourceId !== `${operatorId},${requirementId},${rigId}`
        );
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case OIL_COMPANY_ACTIONS.CLEAR_ELIGIBLE_RIGS_FROM_REQUIREMENT: {
      const { projectId, operatorId, requirementId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        projectsCopy[projectIndex].eligibleRigs = projectsCopy[projectIndex].eligibleRigs?.filter(
          (elig) => elig.requirementId !== requirementId && operatorId !== elig.operatorId
        );
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case OIL_COMPANY_ACTIONS.REMOVE_REQUIREMENT: {
      const { projectId, operatorId, requirementId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        projectsCopy[projectIndex].eligibleRigs = projectsCopy[projectIndex].eligibleRigs?.filter(
          (elig) => elig.operatorId !== operatorId || elig.requirementId !== requirementId
        );

        let customRequirement = projectsCopy[projectIndex].customRequirements?.find(
          (req) => req.operatorId === operatorId || req.contractId === requirementId
        );
        projectsCopy[projectIndex].customRequirements = projectsCopy[
          projectIndex
        ].customRequirements?.filter(
          (req) => req.operatorId !== operatorId || req.contractId !== requirementId
        );
        if (
          (!customRequirement?.originalOperatorId ||
            customRequirement?.originalOperatorId === operatorId) &&
          !projectsCopy[projectIndex].deletedRequirements.find(
            (item) => item.requirementId === requirementId && item.operatorId === operatorId
          )
        ) {
          projectsCopy[projectIndex].deletedRequirements.push({
            requirementId: requirementId,
            operatorId: operatorId
          });
        }
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case OIL_COMPANY_ACTIONS.RESET_OPERATOR: {
      const { projectId, operatorId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        projectsCopy[projectIndex].customRequirements = projectsCopy[
          projectIndex
        ].customRequirements?.filter((req) => req.operatorId !== operatorId);
        projectsCopy[projectIndex].deletedRequirements = projectsCopy[
          projectIndex
        ].deletedRequirements?.filter((req) => req.operatorId !== operatorId);
        tempState.projectsData.projects = projectsCopy;
        projectsCopy[projectIndex].comments = projectsCopy[projectIndex].comments?.filter(
          (item) => item.resourceId !== operatorId || item.eventId !== null
        );
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case OIL_COMPANY_ACTIONS.RESET_CUSTOM_RIG: {
      const { projectId, rigId, resourceId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        projectsCopy[projectIndex].deletedContracts = projectsCopy[
          projectIndex
        ].deletedContracts?.filter((req) => req.rigId !== rigId);

        let customContractIds = projectsCopy[projectIndex].customContracts
          ?.filter((req) => req.rigId === rigId)
          ?.map(({ contractId }) => contractId);

        projectsCopy[projectIndex].deletedRequirements = projectsCopy[
          projectIndex
        ].deletedRequirements?.filter((req) => !customContractIds.includes(req.requirementId));

        projectsCopy[projectIndex].customContracts = projectsCopy[
          projectIndex
        ].customContracts?.filter((req) => req.rigId !== rigId);

        projectsCopy[projectIndex].comments = projectsCopy[projectIndex].comments?.filter(
          (item) => item.resourceId !== resourceId && item.eventId !== null
        );
        // Remove from deleted
        // Remove custom contracts
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case OIL_COMPANY_ACTIONS.ADD_CUSTOM_REQUIREMENT: {
      const { projectId, requirement } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        const project = projectsCopy[projectIndex];
        let contractId = 1;
        if (project?.customRequirements.length) {
          let contractIdMax = Math.max([
            ...project.customRequirements.map(({ contractId }) => contractId)
          ]);
          contractId = contractIdMax + 1;
        }
        project.customRequirements.push({
          ...requirement,
          ...{ contractId: contractId, isRequirement: true, isManualCreate: true, isCustom: true }
        });
        project.lastEditDate = moment().format('DD/MM/yyyy');
        project.lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects[projectIndex] = project;
      }
      return tempState;
    }
    case OIL_COMPANY_ACTIONS.UPDATE_REQUIREMENT: {
      const { projectId, requirement } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        let isCustom = projectsCopy[projectIndex].customRequirements?.some(
          ({ contractId }) => contractId === requirement.contractId
        );

        if (isCustom) {
          projectsCopy[projectIndex].customRequirements =
            projectsCopy[projectIndex]?.customRequirements?.map((item) => {
              if (requirement.contractId === item.contractId) {
                return { ...item, ...requirement };
              }
              return item;
            }) || [];
        } else {
          projectsCopy[projectIndex].customRequirements.push({
            ...requirement,
            isRequirement: true,
            isCustom: !requirement.isManualCreate
          });
          projectsCopy[projectIndex].deletedRequirements.push({
            operatorId: requirement.operatorId,
            requirementId: requirement.contractId
          });
        }
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case OIL_COMPANY_ACTIONS.UPDATE_REQUIREMENT_OPERATOR: {
      const { projectId, requirement, prevOperator } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        if (requirement.isCustom || requirement.isManualCreate) {
          projectsCopy[projectIndex].customRequirements =
            projectsCopy[projectIndex]?.customRequirements?.map((item) => {
              if (requirement.contractId === item.contractId) {
                return { ...item, ...requirement };
              }
              return item;
            }) || [];
        } else {
          projectsCopy[projectIndex].customRequirements.push({
            ...requirement,
            isRequirement: true,
            isCustom: !requirement.isManualCreate
          });
          projectsCopy[projectIndex].deletedRequirements.push({
            operatorId: prevOperator,
            requirementId: requirement.contractId
          });
          projectsCopy[projectIndex].eligibleRigs = projectsCopy[projectIndex].eligibleRigs?.filter(
            ({ requirementId, operatorId }) =>
              operatorId !== prevOperator && requirementId !== requirement.contractId
          );
        }
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case OIL_COMPANY_ACTIONS.RESET_REQUIREMENT: {
      const { projectId, requirementId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        let requirement = projectsCopy[projectIndex].customRequirements.find(
          ({ contractId }) => contractId === requirementId
        );
        projectsCopy[projectIndex].customRequirements = projectsCopy[
          projectIndex
        ].customRequirements?.filter((item) => item.contractId !== requirementId);

        if (requirement.originalContract) {
          projectsCopy[projectIndex].deletedContracts = projectsCopy[
            projectIndex
          ].deletedContracts?.filter((item) => item.contractId !== requirementId);
        }

        projectsCopy[projectIndex].deletedRequirements = projectsCopy[
          projectIndex
        ].deletedRequirements?.filter((item) => item.requirementId !== requirementId);
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case OIL_COMPANY_ACTIONS.RESET_REQUIREMENT_CONTRACT: {
      const { projectId, contract } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        projectsCopy[projectIndex].customContracts = projectsCopy[
          projectIndex
        ].customContracts?.filter(
          (item) =>
            item.contractId !== contract.contractId || item.operatorId !== contract.operatorId
        );

        if (!contract.isManualCreate) {
          projectsCopy[projectIndex].deletedRequirements = projectsCopy[
            projectIndex
          ].deletedRequirements?.filter(
            (requirement) => requirement.requirementId !== contract.contractId
          );
        } else {
          if (contract.originalContract) {
            projectsCopy[projectIndex].deletedContracts = projectsCopy[
              projectIndex
            ].deletedContracts?.filter(
              (requirement) => requirement.contractId !== contract.contractId
            );
          } else {
            projectsCopy[projectIndex].customRequirements.push({
              ...contract,
              ...{ rigId: null, rigName: null }
            });
          }
        }

        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);

        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case OIL_COMPANY_ACTIONS.ASSIGN_REQUIREMENT_TO_RIG: {
      const { projectId, requirement, rigId, rigName } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        projectsCopy[projectIndex].eligibleRigs = projectsCopy[projectIndex].eligibleRigs?.filter(
          (elig) =>
            elig.operatorId !== requirement.operatorId ||
            elig.requirementId !== requirement.contractId
        );
        projectsCopy[projectIndex].customRequirements = projectsCopy[
          projectIndex
        ].customRequirements?.filter(
          (item) =>
            item.contractId !== requirement.contractId || requirement.operatorId !== item.operatorId
        );

        projectsCopy[projectIndex].deletedRequirements.push({
          requirementId: requirement.contractId,
          operatorId: requirement.operatorId
        });

        projectsCopy[projectIndex].customContracts.push({
          ...requirement,
          ...{
            rigId: rigId,
            rigName: rigName,
            isRequirement: false,
            originalRequirement: true,
            isCustom: true
          }
        });

        projectsCopy[projectIndex].eligibleRigs = projectsCopy[projectIndex].eligibleRigs?.filter(
          (elig) =>
            elig.operatorId !== requirement.operatorId || elig.contractId !== requirement.contractId
        );

        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case OIL_COMPANY_ACTIONS.UPDATE_CONTRACT: {
      const { projectId, contract, newFields } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        const contractIndex = projectsCopy[projectIndex].customContracts.findIndex(
          (item) => parseInt(item.contractId) === parseInt(contract.contractId)
        );
        if (contractIndex > -1) {
          projectsCopy[projectIndex].customContracts[contractIndex] = {
            ...contract,
            ...newFields
          };
        } else {
          projectsCopy[projectIndex].customContracts.push({
            ...contract,
            ...newFields,
            ...{ isCustom: true }
          });
          projectsCopy[projectIndex].deletedContracts.push({
            contractId: contract.contractId,
            rigId: contract.rigId
          });
        }

        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case OIL_COMPANY_ACTIONS.REMOVE_CONTRACT_FROM_RIG: {
      const { contract, projectId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        if (!contract.isCustom) {
          projectsCopy[projectIndex].deletedContracts.push({
            contractId: contract.contractId,
            rigId: contract.rigId
          });
        } else {
          projectsCopy[projectIndex].customContracts =
            projectsCopy[projectIndex].customContracts?.filter(
              (item) => item.rigId !== contract.rigId || item.contractId !== contract.contractId
            ) || [];
        }
        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    case OIL_COMPANY_ACTIONS.UPDATE_CONTRACT_TO_REQUIREMENT: {
      const { contract, projectId } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );
      if (projectIndex > -1) {
        // check if original requirement

        if (contract.originalRequirement) {
          projectsCopy[projectIndex].customContracts =
            projectsCopy[projectIndex].customContracts?.filter(
              (item) => item.rigId !== contract.rigId || item.contractId !== contract.contractId
            ) || [];

          projectsCopy[projectIndex].customRequirements.push({
            ...contract,
            ...{
              rigName: null,
              rigId: contract.rigId,
              isCustom: true
            }
          });
        } else {
          projectsCopy[projectIndex].deletedContracts.push({
            contractId: contract.contractId,
            rigId: contract.rigId
          });
          projectsCopy[projectIndex].customRequirements.push({
            ...contract,
            ...{
              rigName: null,
              rigId: null,
              isCustom: true,
              originalContract: true,
              isManualCreate: true
            }
          });
        }

        projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
        projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
        tempState.projectsData.projects = projectsCopy;
      }
      return tempState;
    }
    default:
      return state;
  }
};

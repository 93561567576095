import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Eventcalendar, localeEn, localeNo, setOptions, toast } from '@mobiscroll/react';
import { useStore } from '@store/Store';
import { MONTH_SHORTNAMES } from '@constants';
import {
  Badge,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  PlusIcon,
  Skeleton,
  Stack,
  TuneIcon,
  Typography,
  VisibilityOffOutlinedIcon,
  VisibilityOutlinedIcon
} from '@esgian/esgianui';
import { getAppliedFilterCount, getProject } from '@helpers/helpers';
import { useParams } from 'react-router-dom';
import RequirementsFilterDrawer from '@components/Sections/RigSupplierSection/Drawers/RequirementsFilterDrawer/RequirementsFilterDrawer';
import { RequirementEvent } from '@components/Timeline/EventTypes';
import { FLOW } from '@store/AppReducer';
import { RequirementMenu } from '@components/Timeline';
import { AddEditRequirementModal } from '@components/Modals';
import { getRigDemandFilterDefaults, getRigDemandTimelineData } from '@helpers/RigDemandHelpers';

setOptions({
  locale: localeNo,
  theme: 'ios',
  themeVariant: 'light'
});

function RequirementsTimeline({
  showStatus,
  hideRequirements,
  setHideRequirements,
  calView,
  currentDate,
  height,
  refDate
}) {
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [createRequirementOpen, setCreateRequirementOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterCount, setFilterCount] = useState(null);
  const [operatorsResource, setOperatorsResource] = useState([]);
  const [operatorEvents, setOperatorEvents] = useState([]);
  const [eventMenuOpen, setEventMenuOpen] = useState(false);
  const [eventMenuItem, setEventMenuItem] = useState(null);
  const [mousePosition, setMousePosition] = useState(null);
  const params = useParams();

  const {
    state: {
      themeMode,
      sideMenuOpen,
      filterLookups,
      projectsData: { projects },
      sectionFilters: {
        rigSupply: { chartSettings }
      }
    }
  } = useStore();

  const myScheduleEvent = useCallback(
    (event) => {
      return (
        <RequirementEvent
          flow={FLOW.RIG_SUPPLIER}
          event={event}
          showStatus={showStatus}
          chartSettings={chartSettings}
        />
      );
    },
    [showStatus, chartSettings]
  );

  const onEventCreate = useCallback(() => {
    toast({
      message: 'Requirements can only be moved to the rig supply timeline'
    });
    return false;
  }, []);

  const noOperators = useMemo(() => {
    return !operatorsResource?.length;
  }, [operatorsResource]);

  const onEventUpdated = useCallback(() => {
    return false;
  }, []);

  useEffect(() => {
    if (!projects?.length || !Object.keys(filterLookups).length) return;
    const projectId = params.projectId;
    const { customRequirements, filters } = getProject(projects, projectId);
    const { resource, events } = getRigDemandTimelineData(
      customRequirements,
      filterLookups,
      filters.rigDemand
    );

    const count = getAppliedFilterCount(
      { ...filters.rigDemand },
      getRigDemandFilterDefaults(filterLookups)
    );

    setFilterCount(count);

    setOperatorEvents(events);
    setOperatorsResource(resource);
    setLoading(false);
  }, [JSON.stringify(projects), filterLookups]);

  const onEventClick = useCallback(({ event, domEvent }) => {
    setEventMenuItem(event);
    setMousePosition({ x: domEvent.clientX, y: domEvent.clientY });
    setEventMenuOpen(true);
  }, []);

  const renderCustomResource = useCallback((resource) => {
    return (
      <div
        className="resource-template-content"
        style={{
          height: '100%',
          borderRight: '1px solid lightgray',
          paddingLeft: '0.75em',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <Stack>
          <Stack direction={'row'} spacing={1}>
            <div className="resource-name">{resource.name}</div>
          </Stack>
        </Stack>
      </div>
    );
  }, []);

  return (
    <Stack spacing={2}>
      <Stack spacing={2} sx={{ display: hideRequirements ? 'none' : 'block' }}>
        <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
          <Stack spacing={2} direction={'row'} alignItems={'center'}>
            <Typography bold>Rig Demand</Typography>
            <Typography variant={'body2'}>
              {loading ? (
                <Skeleton variant={'text'} width={'10em'} />
              ) : (
                `Displaying ${operatorEvents?.length || 0} rig requirements`
              )}
            </Typography>
          </Stack>
          <Stack direction={'row'} spacing={2}>
            <Button
              variant={'text'}
              key={'create-requirement'}
              onClick={() => setCreateRequirementOpen(true)}
              startIcon={<PlusIcon color={'inherit'} />}>
              Create requirement
            </Button>
            <Divider orientation={'vertical'} />

            <Button
              variant={'text'}
              onClick={() => setFilterDrawerOpen(true)}
              key={'requirements-filter'}
              color={'secondary'}
              startIcon={
                <Badge invisible={filterCount <= 0} badgeContent={filterCount} color="primary">
                  <TuneIcon color={'inherit'} />
                </Badge>
              }>
              Filters
            </Button>
            <Button
              variant={'text'}
              onClick={() => setHideRequirements(true)}
              key={'hide-requirements-button'}
              color={'secondary'}
              startIcon={<VisibilityOffOutlinedIcon color={'inherit'} />}>
              Hide Chart
            </Button>
          </Stack>
        </Stack>
        <Paper variant={'outlined'}>
          <div style={{ height: '1em', background: '#f5f6f7' }} />
          <Stack spacing={2}>
            <Eventcalendar
              colors={[]}
              className={noOperators ? 'hiddenTimeline' : ''}
              height={`${noOperators ? 6.5 : height}vh`}
              onEventCreate={onEventCreate}
              monthNames={MONTH_SHORTNAMES}
              themeVariant={'light'}
              locale={localeEn}
              data={operatorsResource?.length ? operatorEvents : []}
              onEventUpdated={onEventUpdated}
              onEventClick={onEventClick}
              externalDrop={true}
              renderScheduleEvent={myScheduleEvent}
              renderResource={renderCustomResource}
              resources={operatorsResource?.length ? operatorsResource : null}
              view={calView}
              renderHeader={() => <div style={{ height: 0 }}></div>}
              selectedDate={currentDate}
              refDate={refDate}
            />
            <RequirementMenu
              flow={FLOW.RIG_SUPPLIER}
              isRigDemand
              mousePosition={mousePosition}
              eventMenuOpen={eventMenuOpen}
              handleClose={() => setEventMenuOpen(false)}
              eventMenuItem={eventMenuItem}
            />
            <AddEditRequirementModal
              handleClose={() => setCreateRequirementOpen(false)}
              flow={FLOW.RIG_SUPPLIER}
              open={createRequirementOpen}
            />
            {noOperators && !loading && (
              <Stack sx={{ p: 2 }} spacing={2} alignItems={'center'}>
                <Typography variant={'h6'}>No operators matching selected rigs/filters</Typography>
              </Stack>
            )}
            {loading && (
              <Stack sx={{ p: 2 }} spacing={2} alignItems={'center'}>
                <CircularProgress size={'5em'} />
              </Stack>
            )}
          </Stack>
        </Paper>
      </Stack>
      {hideRequirements && (
        <div
          style={{
            width: `calc(100% - ${sideMenuOpen ? 265 : 57}px)`,
            minHeight: '10vh',
            right: 0,
            position: 'fixed',
            bottom: 0,
            zIndex: 10,
            boxShadow: 'rgb(0 0 0 / 20%) 0px 0px 7px -1px'
          }}>
          <Paper sx={{ minHeight: '10vh', p: 2 }}>
            <Grid container>
              <Grid item xs={12}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack spacing={2} direction={'row'}>
                    <Typography variant={'body2'} bold>
                      Rig Demand
                    </Typography>
                    <Typography variant={'body2'}>
                      {operatorsResource?.length || 0} rig requirements
                    </Typography>
                  </Stack>
                  <Button
                    onClick={() => setHideRequirements(false)}
                    key={'show-requirements-button'}
                    color={'secondary'}
                    startIcon={<VisibilityOutlinedIcon color={'inherit'} />}>
                    Show rig requirements chart
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}
      <RequirementsFilterDrawer
        handleClose={() => setFilterDrawerOpen(false)}
        open={filterDrawerOpen}
      />
    </Stack>
  );
}

RequirementsTimeline.propTypes = {
  showStatus: PropTypes.bool,
  hideRequirements: PropTypes.bool,
  setHideRequirements: PropTypes.func.isRequired,
  calView: PropTypes.object,
  height: PropTypes.number,
  refDate: PropTypes.object,
  currentDate: PropTypes.object
};

RequirementsTimeline.defaultProps = {
  showStatus: false,
  hideRequirements: false
};

export default RequirementsTimeline;

import React, { useState } from 'react';
import { Button, Divider, Grid, ShareOutlinedIcon, Stack, Typography } from '@esgian/esgianui';
import { PlusIcon } from '@esgian/esgianui';
import { CreateProjectModal } from '@components/Modals';
import ImportFileButton from '../../components/Inputs/ImportFileButton';
import { MyProjectsTable } from '@components/Tables';
import { SaveToFileButton } from '@components/Inputs';

function MyProjectsPage() {
  const [createProjectOpen, setCreateProjectOpen] = useState(false);
  return (
    <>
      <Grid container spacing={4} sx={{ pt: 4 }}>
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'h4'}>My Market Planner</Typography>
            <Stack
              direction={'row'}
              divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}
              spacing={1}
              justifyContent={'flex-end'}>
              <Stack spacing={1} direction={'row'}>
                <Button
                  variant={'text'}
                  key={'add-project'}
                  onClick={() => setCreateProjectOpen(true)}
                  startIcon={<PlusIcon color={'inherit'} />}>
                  create a project
                </Button>
                <ImportFileButton />
              </Stack>
              <SaveToFileButton />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <MyProjectsTable setCreateProjectOpen={setCreateProjectOpen} />
        </Grid>
      </Grid>
      <CreateProjectModal
        handleClose={() => setCreateProjectOpen(false)}
        createOpen={createProjectOpen}
      />
    </>
  );
}

MyProjectsPage.propTypes = {};

MyProjectsPage.defaultProps = {};

export default MyProjectsPage;

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from '@store/Store';
import {
  Button,
  CloseIcon,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Slide,
  Stack,
  Typography
} from '@esgian/esgianui';
import { useParams } from 'react-router-dom';
import ApplicableRigsDisplay from '@components/Sections/RigSupplierSection/Drawers/AddRigsDrawer/ApplicableRigsDisplay';
import { getAppliedFilterCount, getProject } from '@helpers/helpers';
import { FLOW, RIG_SUPPLIER_ACTIONS } from '@store/AppReducer';
import Swal from 'sweetalert2';
import { snackbar } from '@mobiscroll/react';
import { RequirementsFiltersContainer } from '@components/filters';
import {
  getRequirementFilterDefaults,
  requirementFilterToRigKey
} from '@components/Sections/RigSupplierSection/Drawers/AddRigsDrawer/AddRigsDefaultFilters';

const getFilteredRigsList = (defaultFilters, lookupRigs, filters) => {
  let includeRigs = [];
  lookupRigs?.forEach((rig) => {
    let include = true;
    Object.entries(filters).forEach(([key, filter]) => {
      if (filter.type === 'multiselect' && filter.value?.length) {
        let filterIds = [...filter.value].map(({ id }) => id);
        if (!filterIds.includes(rig[requirementFilterToRigKey[key]])) {
          include = false;
        }
      }
      if (filter.type === 'bool' && filter.value !== null) {
        if (rig[requirementFilterToRigKey[key]] !== filter.value) {
          include = false;
        }
      }
      if (filter.type === 'range') {
        if (JSON.stringify(defaultFilters[key]) !== JSON.stringify(filter)) {
          if (defaultFilters[key].min === filter.min) {
            if (
              rig[requirementFilterToRigKey[key]] !== null &&
              rig[requirementFilterToRigKey[key]] > filter.max
            ) {
              include = false;
            }
          } else {
            if (
              rig[requirementFilterToRigKey[key]] > filter.max ||
              rig[requirementFilterToRigKey[key]] < filter.min
            ) {
              include = false;
            }
          }
        }
      }
    });
    if (include) {
      includeRigs.push(rig);
    }
  });
  return includeRigs;
};

function AddRigsDrawer({ open, setOpen }) {
  const [filtersCount, setFiltersCount] = useState(0);
  const [filters, setFilters] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [rigsInState, setRigsInState] = useState([]);
  const [selectedRigs, setSelectedRigs] = useState([]);
  const [viewFilters, setViewFilters] = useState(false);
  const [numberOfRigsDisplay, setNumberOfRigsDisplay] = useState(20);
  const [query, setQuery] = useState('');
  const [displayRigs, setDisplayRigs] = useState([]);
  const params = useParams();
  const projectId = params.projectId;
  const {
    state: {
      filterLookups,
      projectsData: { projects }
    },
    dispatch
  } = useStore();

  const { rigs: lookupRigs, rigsType: lookupRigsType = [] } = filterLookups;

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (query === '') {
        setDisplayRigs(lookupRigs);
        setNumberOfRigsDisplay(10);
        return;
      }
      let tempDisplay = [];
      displayRigs.forEach((rig) => {
        if (rig.rigName.toLowerCase().includes(query.toLowerCase())) {
          tempDisplay.push(rig);
        }
      });
      setNumberOfRigsDisplay(10);
      setDisplayRigs(tempDisplay);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [query, lookupRigs]);

  useEffect(() => {
    if (!filterLookups) return;
    setFilters(getRequirementFilterDefaults(filterLookups));
    setAppliedFilters(getRequirementFilterDefaults(filterLookups));
  }, [filterLookups]);

  useEffect(() => {
    const { rigs } = getProject(projects, projectId);
    setRigsInState(rigs);
  }, [JSON.stringify(projects)]);

  const handleApplyFilters = useCallback(
    (reset = false) => {
      setAppliedFilters({ ...filters });
      let defaultFilters = getRequirementFilterDefaults(filterLookups);
      const count = reset ? 0 : getAppliedFilterCount(filters, defaultFilters);
      if (count > 0) {
        setDisplayRigs(getFilteredRigsList(defaultFilters, lookupRigs, filters));
      } else {
        setDisplayRigs(lookupRigs);
      }
      setFiltersCount(count);
      setViewFilters(false);
      setNumberOfRigsDisplay(10);
    },
    [filters, filterLookups]
  );

  const addRigsToState = useCallback(
    (rigs) => {
      if (!rigsInState?.length) {
        const { filters } = getProject(projects, projectId);
        const rigTypeIds = [...new Set(rigs.map((item) => item.rigTypeId))];
        const rigTypesFilters = [...lookupRigsType]?.filter(({ id }) => rigTypeIds?.includes(id));
        const tempFilters = { ...filters?.rigDemand };
        tempFilters.rigTypes.value = rigTypesFilters;
        dispatch({
          type: RIG_SUPPLIER_ACTIONS.UPDATE_RIG_DEMAND_FILTERS,
          flow: FLOW.RIG_SUPPLIER,
          payload: {
            projectId: projectId,
            filters: tempFilters
          }
        });
      }
      dispatch({
        type: RIG_SUPPLIER_ACTIONS.ADD_RIGS_TO_PROJECT,
        flow: FLOW.RIG_SUPPLIER,
        payload: { projectId: params.projectId, rigs: rigs }
      });
      snackbar({
        message: `Rigs added to project`,
        color: 'success',
        duration: 5000,
        display: 'top'
      });
      setFilters(getRequirementFilterDefaults(filterLookups));
      setAppliedFilters(getRequirementFilterDefaults(filterLookups));
      setSelectedRigs([]);
    },
    [lookupRigsType, projectId]
  );

  const handleAddRigs = useCallback(() => {
    let rigs = [...selectedRigs].map((rig) => {
      let copy = { ...rig };
      if (copy?.contracts) {
        delete copy.contracts;
      }
      if (copy?.activities) {
        delete copy.activities;
      }
      return copy;
    });
    setOpen(false);
    if (rigs?.length <= 10) {
      addRigsToState(rigs);
      return;
    }
    Swal.fire({
      title: 'Are you sure?',
      text: `You're about to add ${rigs.length} to your project`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        addRigsToState(rigs);
      } else {
        setOpen(true);
      }
    });
  }, [selectedRigs]);

  const handleScroll = useCallback(
    (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom && !viewFilters) {
        setNumberOfRigsDisplay(numberOfRigsDisplay + 10);
      }
    },
    [numberOfRigsDisplay, viewFilters]
  );
  return (
    <>
      <Drawer open={open} onClose={() => setOpen(false)} anchor={'right'}>
        <Grid
          container
          sx={{
            width: '25vw',
            maxHeight: '100%'
          }}>
          <Grid sx={{ height: '57px' }} item xs={12}>
            <Grid justifyContent={'space-between'} container>
              <Grid item alignSelf={'center'} sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
                <Typography variant={'h6'} color={'text.primary'}>
                  {viewFilters ? 'Filters' : 'Add rigs'}
                </Typography>
              </Grid>
              <Grid item sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
                <IconButton
                  onClick={() => {
                    if (viewFilters) {
                      setViewFilters(false);
                      setFilters(appliedFilters);
                    } else {
                      setOpen(false);
                    }
                    setNumberOfRigsDisplay(10);
                  }}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack
              onScroll={handleScroll}
              justifyContent={'space-between'}
              sx={{
                overflowX: 'hidden',
                height: 'calc(100vh - 57px - 70px )',
                overflowY: 'auto'
              }}>
              {viewFilters ? (
                <RequirementsFiltersContainer filters={filters} setFilters={setFilters} />
              ) : (
                <ApplicableRigsDisplay
                  setNumberOfRigsDisplay={setNumberOfRigsDisplay}
                  numberOfRigsDisplay={numberOfRigsDisplay}
                  rigsInState={rigsInState}
                  filtersCount={filtersCount}
                  setViewFilters={setViewFilters}
                  displayRigs={displayRigs}
                  setSelectedRigs={setSelectedRigs}
                  selectedRigs={selectedRigs}
                  setQuery={setQuery}
                  query={query}
                />
              )}
              <Stack
                direction={'row'}
                sx={{
                  p: 2,
                  boxShadow: '0 0 8px rgb(0 0 0 / 18%)',
                  position: 'fixed',
                  bottom: 0,
                  height: '70px',
                  background: (theme) => theme.palette.background.paper,
                  width: '25vw'
                }}
                justifyContent={'space-between'}>
                <Button
                  color={'secondary'}
                  onClick={() => {
                    if (viewFilters) {
                      setFilters(getRequirementFilterDefaults(filterLookups));
                      handleApplyFilters(true);
                    } else {
                      setSelectedRigs([]);
                    }
                  }}
                  variant={'text'}
                  withUnderline>
                  Clear All
                </Button>
                <Button
                  disabled={!viewFilters && !selectedRigs?.length}
                  disableElevation
                  onClick={() => {
                    if (viewFilters) {
                      handleApplyFilters();
                    } else {
                      handleAddRigs();
                    }
                  }}
                  variant={'contained'}>
                  {viewFilters ? 'Apply' : 'Add rigs'}
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}

AddRigsDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

AddRigsDrawer.defaultProps = {
  requirementTimelineFilters: null
};

export default AddRigsDrawer;

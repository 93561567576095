import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Checkbox,
  CircularProgress,
  Paper,
  PlusIcon,
  RigIcon,
  Stack,
  SubdirectoryArrowRightIcon,
  Typography
} from '@esgian/esgianui';
import {
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Datepicker,
  Eventcalendar,
  formatDate,
  getJson,
  localeEn,
  localeNo,
  setOptions,
  snackbar,
  toast
} from '@mobiscroll/react';
import moment from 'moment/moment';
import TimelineCustomHeader from '../../../Timeline/TimelineCustomHeader';
import { getProject } from '@helpers/helpers';
import { EVENT_TYPES, MONTH_SHORTNAMES } from '@constants';
import {
  getEligibleRigsEvents,
  getEventObj
} from '@components/Sections/OilCompanySection/RigDemandTimeline/RigDemandTimelineHelpers';
import { useStore } from '@store/Store';
import { useParams } from 'react-router-dom';
import OperatorResourceButton from '@components/Timeline/ResourceButtons/OperatorResourceButton';
import { ContractMenu, CustomRigResourceButton } from '@components/Timeline';
import RequirementResourceButton from '../../../Timeline/ResourceButtons/RequirementResourceButton';
import EligibleRigResourceButton from '../../../Timeline/ResourceButtons/EligibleRigResourceButton';
import { ActivityEvent, RequirementEvent } from '@components/Timeline/EventTypes';
import ContractEvent from '../../../Timeline/EventTypes/ContractEvent';
import { FLOW, OIL_COMPANY_ACTIONS } from '@store/AppReducer';
import { getUpdatedContractDrag, getUpdatedRequirementDrag } from '@helpers/timelineHelpers';
import RequirementMenu from '../../../Timeline/RequirementMenu';
import AssignRequirementModal from '@components/Modals/AssignRequirementModal';
setOptions({
  locale: localeNo,
  theme: 'ios',
  themeVariant: 'light'
});

function DemandTimelineTwo({
  showSelectResource,
  selectedResources,
  setSelectedResources,
  setRequirementModalOpen,
  setAddRequirementOpen,
  showStatus
}) {
  const [resources, setResources] = useState([]);
  const [resourceRequirements, setResourceRequirements] = useState([]);
  const [eventMenuOpen, setEventMenuOpen] = useState({ requirement: false, contract: false });
  const [mousePosition, setMousePosition] = useState(null);
  const [eventMenuItem, setEventMenuItem] = useState(null);
  const [reqToContractItem, setReqToContractItem] = useState(null);
  const [loadingTimeline, setLoadingTimeline] = useState(true);
  const [eligibleIds, setEligibleIds] = useState([]);
  const [refDate, setRefDate] = React.useState();
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [rangeVal, setRangeVal] = React.useState([]);
  const [buttonText, setButtonText] = React.useState([]);
  const params = useParams();
  const startDate = React.useRef();
  const endDate = React.useRef();
  const [calView, setCalView] = React.useState({
    timeline: {
      type: 'year',
      resolution: 'year',
      size: 4,
      eventList: false
    }
  });
  const {
    state: {
      themeMode,
      filterLookups,
      sectionFilters: {
        rigSupply: { chartSettings }
      },
      projectsData: { projects }
    },
    dispatch
  } = useStore();
  const { contractsWithoutRigs, rigs: lookupRigs } = filterLookups;

  // returns the formatted date
  const getFormattedRange = React.useCallback(
    (start, end) => {
      if (calView.timeline.resolution === 'year') {
        return `${moment(start).year()} - ${moment(end).year()}`;
      } else {
        return `${moment(start).format('MMM, yyyy')} - ${moment(end).format('MMM, yyyy')}`;
      }
    },
    [calView]
  );

  const onPageLoaded = React.useCallback(
    (args) => {
      const sDate = args.firstDay;
      const end = args.lastDay;
      const eDate = new Date(end.getFullYear(), end.getMonth(), end.getDate() - 1, 0);
      startDate.current = sDate;
      endDate.current = eDate;
      setTimeout(() => {
        // set button text
        setButtonText(getFormattedRange(sDate, eDate));
        // set range value
        setRangeVal([sDate, eDate]);
        // navigate the calendar
        setCurrentDate(sDate);
      });
    },
    [getFormattedRange]
  );

  const onSelectedDateChange = React.useCallback(
    (event) => {
      setCurrentDate(event.date);
    },
    [setCurrentDate]
  );

  const onEventClick = useCallback(({ event, domEvent }) => {
    setEventMenuItem(event);
    setMousePosition({ x: domEvent.clientX, y: domEvent.clientY + 20 });
    if (event.type === EVENT_TYPES.CONTRACT) {
      setEventMenuOpen({ ...eventMenuOpen, contract: true });
    } else {
      setEventMenuOpen({ ...eventMenuOpen, requirement: true });
    }
  }, []);

  const onEventCreate = useCallback(
    ({ event }) => {
      let { start, end, details } = event;
      start = moment(start);
      end = moment(end);
      let updateEventDetails = JSON.parse(JSON.stringify(details));
      const { duration } = updateEventDetails;
      const resourceChanged = event.resource !== event.prevResource;

      let dispatchType = OIL_COMPANY_ACTIONS.UPDATE_REQUIREMENT_OPERATOR;
      let payload = { projectId: params.projectId };
      let message = 'Requirement updated';

      if (resourceChanged) {
        if (typeof event.resource === 'number') {
          let operator = resources?.find(({ id }) => id === event.resource);
          updateEventDetails.originalOperatorId =
            updateEventDetails.originalOperatorId || updateEventDetails.operatorId;
          updateEventDetails.operatorId = event.resource;
          updateEventDetails.operator = operator.name;
          if (event.type === EVENT_TYPES.CONTRACT) {
            message = 'Contract updated to requirement';
            dispatchType = OIL_COMPANY_ACTIONS.UPDATE_CONTRACT_TO_REQUIREMENT;
          }
          payload.prevOperator = event.prevResource;
        } else {
          let idArray = event.resource.split(',');
          if (idArray.length === 2 && idArray[0] !== 'rig') {
            toast({
              message: `Requirements can only be added to operators or rigs.`
            });
          } else {
            if (event.type === EVENT_TYPES.REQUIREMENT) {
              setReqToContractItem(event);
              return false;
            }
            let prevIdArray = event?.prevResource?.split(',') || [];
            let rigId = parseInt(idArray[1]);
            if (idArray[0] === 'rig') {
              if (prevIdArray.length === 3 && prevIdArray[2] === idArray[1]) {
                return false;
              }
            } else {
              if (idArray.length === 3) {
                if (prevIdArray.length === 3 && prevIdArray[2] === idArray[2]) {
                  return false;
                }
                if (prevIdArray[0] === 'rig' && prevIdArray[1] === idArray[2]) {
                  return false;
                }
                rigId = parseInt(idArray[2]);
              }
            }
            dispatchType = OIL_COMPANY_ACTIONS.UPDATE_CONTRACT;
            let rig = lookupRigs?.find(({ rigId: id }) => id === rigId);
            payload.newFields = { rigId: rig.rigId, rigName: rig.rigName };
            message = 'Contract updated';
          }
        }
      } else {
        if (event.type === EVENT_TYPES.REQUIREMENT) {
          updateEventDetails.startDate = start.format('yyyy-MM-DD');
          updateEventDetails.latestStartDate = end.subtract(duration, 'days').format('yyyy-MM-DD');
          dispatchType = OIL_COMPANY_ACTIONS.UPDATE_REQUIREMENT;
        } else {
          updateEventDetails.startDate = start.format('yyyy-MM-DD');
          updateEventDetails.endDate = end.format('yyyy-MM-DD');
          dispatchType = OIL_COMPANY_ACTIONS.UPDATE_CONTRACT;
          payload.newFields = {
            startDate: start.format('yyyy-MM-DD'),
            endDate: end.format('yyyy-MM-DD')
          };
          message = 'Contract updated';
        }
      }
      payload = {
        ...payload,
        ...{ requirement: updateEventDetails, contract: updateEventDetails }
      };

      dispatch({
        type: dispatchType,
        flow: FLOW.OIL_COMPANY,
        payload: payload
      });
      snackbar({
        message: message,
        color: 'success',
        duration: 5000,
        display: 'top'
      });

      return false;
    },
    [resourceRequirements, resources, lookupRigs]
  );
  const onEventUpdate = useCallback(
    ({ event }) => {
      let message = 'Requirement updated';
      let isRequirement = true;

      if (typeof event.resource === 'string') {
        let ids = event.resource.split(',');
        if (ids[0] === 'rig') {
          isRequirement = false;
        }
        if (ids.length === 3) {
          isRequirement = false;
        }
      }

      let { start, end, details } = event;
      if (isRequirement) {
        dispatch({
          type: OIL_COMPANY_ACTIONS.UPDATE_REQUIREMENT,
          flow: FLOW.OIL_COMPANY,
          payload: {
            projectId: params.projectId,
            requirement: getUpdatedRequirementDrag(start, end, details)
          }
        });
      } else {
        message = 'Contract updated';
        dispatch({
          type: OIL_COMPANY_ACTIONS.UPDATE_CONTRACT,
          flow: FLOW.OIL_COMPANY,
          payload: {
            projectId: params.projectId,
            contract: details,
            newFields: getUpdatedContractDrag(start, end, details)
          }
        });
      }
      snackbar({
        message: message,
        color: 'success',
        duration: 5000,
        display: 'top'
      });
      return false;
    },
    [resourceRequirements]
  );
  const customWithNavButtons = () => {
    return (
      <TimelineCustomHeader
        setRangeVal={setRangeVal}
        rangeVal={rangeVal}
        startDate={startDate}
        endDate={endDate}
        setCalView={setCalView}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        refDate={refDate}
        setRefDate={setRefDate}
        buttonText={buttonText}
        setButtonText={setButtonText}
        calView={calView}
      />
    );
  };

  const handleResourceSelect = useCallback(
    (resource) => {
      if (selectedResources.includes(resource.id)) {
        setSelectedResources([...selectedResources].filter((item) => item !== resource.id));
      } else {
        setSelectedResources([...selectedResources, resource.id]);
      }
    },
    [selectedResources]
  );

  const renderCustomResource = useCallback(
    (resource) => {
      let paddingLeft = showSelectResource ? '0em' : '0.75em';
      if (!resource.children?.length) {
        paddingLeft = `2em`;
      }
      if (resource.level === 3) {
        paddingLeft = '4.5em';
      }
      return (
        <div
          className="resource-template-content"
          style={{
            background: resource.level > 1 ? '#f3f3f4' : 'transparent',
            borderRight: '1px solid lightgray',
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Stack>
            <Stack
              alignItems={'center'}
              sx={{ paddingLeft: `${paddingLeft}` }}
              direction={'row'}
              spacing={1}>
              {resource.level === 1 && !resource.isOperator && <RigIcon fontSize={'small'} />}
              {resource.level === 3 && <SubdirectoryArrowRightIcon />}
              {showSelectResource && (
                <Checkbox
                  sx={{ marginLeft: '0 !important' }}
                  onChange={() => handleResourceSelect(resource)}
                  checked={selectedResources.includes(resource.id)}
                  size="small"
                />
              )}
              <Stack>
                {resource.level === 1 && resource.isOperator && (
                  <OperatorResourceButton resource={resource} />
                )}
                {resource.level === 1 && !resource.isOperator && (
                  <CustomRigResourceButton resource={resource} />
                )}
                {resource.level === 2 && <RequirementResourceButton resource={resource} />}
                {resource.level === 3 && <EligibleRigResourceButton resource={resource} />}
              </Stack>
            </Stack>
          </Stack>
        </div>
      );
    },
    [selectedResources, showSelectResource]
  );

  const myScheduleEvent = useCallback(
    (event) => {
      if (event.original.type === EVENT_TYPES.ACTIVITY) {
        return (
          <ActivityEvent event={event} chartSettings={chartSettings} showStatus={showStatus} />
        );
      }
      if (event.original.type === EVENT_TYPES.CONTRACT) {
        return (
          <ContractEvent event={event} chartSettings={chartSettings} showStatus={showStatus} />
        );
      }
      return (
        <RequirementEvent
          flow={FLOW.OIL_COMPANY}
          event={event}
          chartSettings={chartSettings}
          showStatus={showStatus}
        />
      );
    },
    [showStatus, chartSettings]
  );

  useEffect(() => {
    if (!projects?.length || !contractsWithoutRigs?.length || !lookupRigs?.length) return;
    const projectId = params.projectId;
    const {
      operators,
      deletedRequirements,
      eligibleRigs,
      customRequirements,
      customContracts,
      comments,
      deletedContracts
    } = getProject(projects, projectId);
    let eligIds = [];
    let oprResources = operators?.map((operator) => {
      let eligible = eligibleRigs.filter((rig) => rig.operatorId === operator.id) || [];
      let children = eligible?.map(({ requirementId, operatorId, rigs }) => {
        let eligReq = contractsWithoutRigs?.find(({ contractId }) => contractId === requirementId);
        if (!eligReq) {
          eligReq = customRequirements?.find(({ contractId }) => contractId === requirementId);
        }
        let eligRigs = rigs?.map((rig) => {
          let resourceId = `${operatorId},${eligReq.contractId},${rig.rigId}`;
          eligIds.push(resourceId);
          return {
            level: 3,
            id: resourceId,
            name: rig.rigName,
            rigType: rig.rigType,
            color: '#595959'
          };
        });
        return {
          level: 2,
          children: eligRigs,
          id: `${operatorId},${requirementId}`,
          name: eligReq.projectName || 'Requirement',
          color: '#595959'
        };
      });

      return {
        ...operator,
        children: children,
        isOperator: true,
        level: 1,
        ...{ color: '#595959' }
      };
    });
    let rigIds = customContracts?.map(({ rigId }) => rigId);
    let rigsResource = [];
    let rigsEvents = [];

    lookupRigs?.forEach((rig) => {
      if (rigIds.includes(rig.rigId)) {
        rigsResource.push({
          level: 1,
          isOperator: false,
          id: `rig,${rig.rigId}`,
          name: rig.rigName,
          rigType: rig.rigType,
          color: '#595959'
        });
        let customIds = customContracts?.map(({ contractId }) => contractId);
        let deletedIds = deletedContracts?.map(({ contractId }) => contractId);
        let excludedIds = [...customIds, ...deletedIds];
        if (rig.contracts?.length) {
          rig.contracts
            ?.filter((event) => !excludedIds.includes(event.contractId))
            ?.forEach((contract) =>
              rigsEvents.push({
                details: { ...contract, type: EVENT_TYPES.CONTRACT },
                editable: true,
                start: moment(contract.startDate, 'yyyy-MM-DD'),
                end: moment(contract.endDate, 'yyyy-MM-DD'),
                resource: `rig,${contract.rigId}`,
                title: contract.projectName || contract.operator,
                type: EVENT_TYPES.CONTRACT,
                taskType: contract.contractStatusId
              })
            );
        }
        if (rig.activities?.length) {
          rig.activities.forEach((activity) =>
            rigsEvents.push({
              details: { ...activity, type: EVENT_TYPES.ACTIVITY },
              editable: false,
              start: moment(activity.startDate, 'yyyy-MM-DD'),
              end: moment(activity.endDate, 'yyyy-MM-DD'),
              resource: `rig,${activity.rigId}`,
              title: activity.rigStatus,
              type: EVENT_TYPES.ACTIVITY,
              taskType: activity.rigStatusId
            })
          );
        }
      }
    });
    setResources([...oprResources, ...rigsResource]);
    setEligibleIds(eligIds);
    let operatorIds = operators?.map(({ id }) => id);
    let requirements = [...contractsWithoutRigs]?.filter(
      (item) =>
        operatorIds.includes(item.operatorId) &&
        !deletedRequirements?.map(({ requirementId }) => requirementId).includes(item.contractId)
    );

    let eligibleContractEvents = getEligibleRigsEvents(
      eligibleRigs,
      lookupRigs,
      comments,
      customContracts,
      deletedContracts
    );

    let customRigContractEvents = customContracts?.map((item) => {
      return {
        details: item,
        start: item.startDate,
        level: 1,
        end: moment(item.startDate).add(item.duration, 'days'),
        resource: `rig,${item.rigId}`,
        title: item.projectName || item.operator,
        type: EVENT_TYPES.CONTRACT,
        taskType: item.contractStatusId
      };
    });
    let requirementEvents =
      [...requirements, ...customRequirements]?.map((requirement) =>
        getEventObj(requirement, eligibleRigs)
      ) || [];

    setResourceRequirements([
      ...eligibleContractEvents,
      ...requirementEvents,
      ...customRigContractEvents,
      ...rigsEvents
    ]);
    setLoadingTimeline(false);
  }, [JSON.stringify(projects), contractsWithoutRigs, lookupRigs]);

  const noOperators = !resources?.length;
  const colors = useMemo(() => {
    return [
      {
        start: moment().subtract(100, 'year').format('yyyy-MM-DD'),
        end: moment().add(100, 'years').format('yyyy-MM-DD'),
        resource: [eligibleIds],
        background: '#f3f3f4'
      }
    ];
  }, [eligibleIds]);
  console.log('render');
  return (
    <Stack spacing={2}>
      <Paper variant={'outlined'} sx={{ borderRadius: '0px 4px 0px 4px' }}>
        <Stack spacing={2}>
          <div id={'rig-demand-timeline'}>
            <div style={{ height: `${noOperators ? 0 : 75}vh`, overflow: 'auto' }}>
              <Eventcalendar
                returnFormat={'moment'}
                // colors={[colors]}
                colors={colors}
                onEventUpdate={onEventUpdate}
                className={noOperators ? 'hiddenTimeline' : ''}
                onEventCreate={onEventCreate}
                monthNames={MONTH_SHORTNAMES}
                themeVariant={'light'}
                showEventTooltip={false}
                onEventClick={onEventClick}
                locale={localeEn}
                data={resourceRequirements}
                externalDrop={!!resources?.length}
                dragToResize={true}
                renderScheduleEvent={myScheduleEvent}
                renderResource={renderCustomResource}
                resources={resources?.length ? resources : null}
                view={calView}
                renderHeader={customWithNavButtons}
                selectedDate={currentDate}
                refDate={refDate}
                onPageLoaded={onPageLoaded}
                onSelectedDateChange={onSelectedDateChange}
              />{' '}
            </div>
          </div>
          {loadingTimeline && (
            <Stack sx={{ p: 2 }} spacing={2} alignItems={'center'}>
              <CircularProgress size={'5em'} />
            </Stack>
          )}
          {noOperators && !loadingTimeline && (
            <Stack sx={{ p: 2 }} spacing={2}>
              <Typography variant={'subtitle1'} bold>
                Create or add requirement to start rig supply analysis
              </Typography>
              <Stack direction={'row'} spacing={2}>
                <Button
                  variant={'outlined'}
                  onClick={() => setRequirementModalOpen(true)}
                  startIcon={<PlusIcon color={'inherit'} />}>
                  Create requirement
                </Button>
                <Button
                  variant={'outlined'}
                  onClick={() => setAddRequirementOpen(true)}
                  startIcon={<PlusIcon color={'inherit'} />}>
                  Add requirement
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Paper>
      <RequirementMenu
        flow={FLOW.OIL_COMPANY}
        mousePosition={mousePosition}
        eventMenuOpen={eventMenuOpen.requirement}
        handleClose={() => setEventMenuOpen({ ...eventMenuOpen, requirement: false })}
        eventMenuItem={eventMenuItem}
      />
      <ContractMenu
        flow={FLOW.OIL_COMPANY}
        mousePosition={mousePosition}
        eventMenuOpen={eventMenuOpen.contract}
        handleClose={() => setEventMenuOpen({ ...eventMenuOpen, contract: false })}
        eventMenuItem={eventMenuItem}
      />
      {!!reqToContractItem && (
        <AssignRequirementModal
          handleClose={() => setReqToContractItem(null)}
          open={true}
          projectId={parseInt(params.projectId)}
          flow={FLOW.OIL_COMPANY}
          requirementEvent={reqToContractItem}
        />
      )}
    </Stack>
  );
}

DemandTimelineTwo.propTypes = {
  showSelectResource: PropTypes.bool,
  selectedResources: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  setSelectedResources: PropTypes.func.isRequired,
  setRequirementModalOpen: PropTypes.func.isRequired,
  setAddRequirementOpen: PropTypes.func.isRequired,
  showStatus: PropTypes.bool
};

DemandTimelineTwo.defaultProps = {
  showSelectResource: false,
  selectedResources: [],
  showStatus: false
};

export default DemandTimelineTwo;

import { getRigSupplierActions } from '@store/RigSupplierActions';
import { getGeneralActions } from '@store/GeneralActions';
import { getOilCompanyActions } from '@store/OilCompanyActions';
export const getUserFirstAndLast = (state) => {
  const { profile } = state.user || {};
  const { lastName = '', firstName = '' } = profile || {};
  return `${firstName} ${lastName}`;
};
export const FLOW = {
  RIG_SUPPLIER: 'rig_supplier',
  OIL_COMPANY: 'oil_company',
  GENERAL: 'general'
};

export const OIL_COMPANY_ACTIONS = {
  ADD_OPERATORS_TO_PROJECT: 'add_operators_to_project',
  ADD_CUSTOM_REQUIREMENT: 'add_custom_requirement',

  ASSIGN_REQUIREMENT_TO_RIG: 'assign_requirement_to_rig',
  CLEAR_ELIGIBLE_RIGS_FROM_REQUIREMENT: 'clear_eligible_rigs_from_requirement',

  UPDATE_CONTRACT_TO_REQUIREMENT: 'update_contract_to_requirement',
  UPDATE_REQUIREMENT: 'update_requirement',
  UPDATE_REQUIREMENT_OPERATOR: 'update_requirement_operator',
  UPDATE_CONTRACT: 'update_contract',

  RESET_REQUIREMENT_CONTRACT: 'reset_requirement_contract',
  RESET_OPERATOR: 'reset_operator',
  RESET_CUSTOM_RIG: 'reset_custom_rig',
  RESET_REQUIREMENT: 'reset_requirement',
  RESET_CONTRACT: 'reset_contract',

  REMOVE_ELIGIBLE_RIG_FOR_REQUIREMENT: 'remove_eligible_rigs_for_requirement',
  REMOVE_OPERATOR_FROM_PROJECT: 'remove_operator_from_project',
  REMOVE_CONTRACT_FROM_RIG: 'remove_contract_from_rig',
  REMOVE_REQUIREMENT: 'remove_requirement'
};

export const GENERAL_ACTIONS = {
  SET_LOOKUP_DATA: 'set_lookup_data',
  SET_PROJECTS_DATA: 'set_projects_data',
  SET_RIG_SUPPLY_CHART_SETTING: 'set_rig_supply_chart_settings',
  SET_USER: 'set_user',
  CREATE_PROJECT: 'create_project',
  UPDATE_PROJECT: 'update_project',
  UPDATE_THEME_MODE: 'update_theme_mode',
  UPDATE_MENU_OPEN: 'update_menu_open',
  DELETE_PROJECT: 'delete_project',
  ADD_OR_UPDATE_COMMENT: 'add_or_update_comment',
  REMOVE_COMMENT: 'remove_comment'
};

export const RIG_SUPPLIER_ACTIONS = {
  ADD_RIGS_TO_PROJECT: 'add_rigs_to_project',
  REMOVE_RIG_FROM_PROJECT: 'remove_rig_from_project',
  RESET_RIG: 'reset_rig',
  ADD_RIG_COMMENT: 'add_rig_comment',
  UPDATE_RIG_DEMAND_FILTERS: 'update_rig_demand_filters',

  UPDATE_RIG_CONTRACT: 'update_rig_contract',
  RESET_CONTRACT: 'reset_contract',
  REMOVE_CONTRACT_FROM_RIG: 'remove_contract_from_rig',

  CREATE_REQUIREMENT: 'create_requirement',
  UPDATE_REQUIREMENT: 'update_requirement',
  RESET_REQUIREMENT: 'reset_requirement',
  REMOVE_REQUIREMENT: 'remove_requirement',

  UPDATE_ELIGIBLE_RIGS_FOR_REQUIREMENT: 'update_eligible_rigs_for_requirement',
  REMOVE_ELIGIBLE_RIGS_FOR_REQUIREMENT: 'remove_eligible_rigs_for_requirement',
  REMOVE_ELIGIBLE_RIG_FOR_REQUIREMENT: 'remove_eligible_rig_for_requirement',

  ASSIGN_REQUIREMENT_TO_RIG: 'assign_requirement_to_rig'
};
// Use the initialState as a default value
export function AppReducer(state, action) {
  if (action.flow === FLOW.GENERAL) {
    return getGeneralActions(state, action);
  }
  if (action.flow === FLOW.RIG_SUPPLIER) {
    return getRigSupplierActions(state, action);
  }
  if (action.flow === FLOW.OIL_COMPANY) {
    return getOilCompanyActions(state, action);
  }
}

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, ReportIcon, Stack, Typography } from '@esgian/esgianui';

import RigDemandTimelineContainer from '@components/Sections/OilCompanySection/RigDemandTimelineContainer/RigDemandTimelineContainer';
import SaveToFileButton from '../../Inputs/SaveToFileButton';
import { getProject } from '@helpers/helpers';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { useParams } from 'react-router-dom';
import { useStore } from '@store/Store';

function OilCompanySection({ project }) {
  const params = useParams();
  const {
    state: {
      projectsData: { projects }
    }
  } = useStore();

  const exportPdf = useCallback(async () => {
    let legend = document.querySelector('#timeline-legend');
    legend.style.width = '100vw';
    let timeline = document.querySelector('#rig-demand-timeline');
    let prevHeight = timeline.style.height;
    timeline.style.height = 'fit-content';
    timeline.style.maxHeight = 'fit-content';
    const { projectName, note, createdBy, createdDate, lastEditBy, lastEditDate } = getProject(
      projects,
      params.projectId
    );
    const imgWidth = 275;
    const pageHeight = 210;
    let position = 35;
    const doc = new jsPDF('landscape', 'mm', 'a4');
    await html2canvas(legend).then((canvas) => {
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL('image/png');
      doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      position += imgHeight + 5;
    });
    legend.removeAttribute('style');
    await html2canvas(timeline).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight + 25;

      doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight - 20;
      doc.setLineWidth(0.05);
      doc.setDrawColor(0, 0, 0);
      doc.line(0, 30, 297, 30);
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight + 10;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        doc.setFillColor(255, 255, 255);
        doc.rect(0, 0, 297, 10, 'F');
        doc.line(0, 10, 297, 10);

        heightLeft -= pageHeight - 10;
      }
      doc.setFontSize(10).setFont(undefined, 'normal');
      for (let pageNumber = 1; pageNumber <= doc.getNumberOfPages(); pageNumber++) {
        doc.setPage(pageNumber);
        doc.text(
          `Page ${pageNumber} of ${doc.getNumberOfPages()}`,
          imgWidth - 8,
          pageNumber === 1 ? 25 : 7
        );
      }
      doc.setPage(1);
      doc.setFontSize(10).setFont(undefined, 'bold');
      doc.text(`Project name:`, 10, 10);
      doc.text('Note:', 10, 15);
      doc.text('Created By:', 10, 20);
      doc.text(`Created:`, 10, 25);
      doc.text('Last edited by:', 80, 20);
      doc.text('Last edited:', 80, 25);
      doc.setFont(undefined, 'normal');
      doc.text(projectName, 40, 10);
      doc.text(note || '-', 40, 15);
      doc.text(createdBy, 40, 20);
      doc.text(createdDate, 40, 25);

      doc.setFont(undefined, 'normal').text(lastEditBy, 110, 20);
      doc.setFont(undefined, 'normal').text(lastEditDate, 110, 25);
    });
    doc.save('rigs-planner-summary.pdf');

    timeline.style.height = prevHeight;
    timeline.style.maxHeight = '75vh';
  }, [JSON.stringify(projects)]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'h5'} bold>
            WORKBOOK
          </Typography>
          <Stack direction={'row'} spacing={2}>
            <Button
              variant={'text'}
              onClick={exportPdf}
              sx={{ color: '#000000' }}
              startIcon={<ReportIcon color={'inherit'} />}>
              Export
            </Button>
            <SaveToFileButton projectId={parseInt(params.projectId)} />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <RigDemandTimelineContainer project={project} />
      </Grid>
    </Grid>
  );
}

OilCompanySection.propTypes = {
  project: PropTypes.object.isRequired
};

OilCompanySection.defaultProps = {};

export default OilCompanySection;

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ChecklistIcon,
  Divider,
  Grid,
  PlusIcon,
  Stack,
  Typography
} from '@esgian/esgianui';
import RigSupplyTimeline from '@components/Sections/RigSupplierSection/RigSupplyTimeline';
import RequirementsTimeline from '@components/Sections/RigSupplierSection/RequirementsTimeline';
import { EditEventModule } from '@components/Sections/RigSupplierSection/Modules';
import moment from 'moment/moment';
import { useStore } from '@store/Store';
import { TimelineLegend } from '@components';
import { RigsChartSettingButton } from '@components/Sections/RigSupplierSection/Menues';
import { FLOW, RIG_SUPPLIER_ACTIONS } from '@store/AppReducer';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { snackbar } from '@mobiscroll/react';
import SelectedTimelineResourcesDisplay from '../../Display/SelectedTimelineResourcesDisplay';

function TimelineContainer({ project, addRigsButtonClick, setRemoveRig, setViewRigInfo }) {
  const [showSelectResource, setShowSelectResource] = useState(false);
  const [selectedResources, setSelectedResources] = useState([]);
  const [showStatus, setShowStatus] = useState(false);
  const [editEvent, setEditEvent] = useState(null);
  const [hideRequirements, setHideRequirements] = useState(false);
  const [height, setHeight] = useState(45);
  const [currentDate, setCurrentDate] = useState(moment());
  const params = useParams();
  const [refDate, setRefDate] = useState();
  const [calView, setCalView] = useState({
    timeline: {
      type: 'year',
      size: 4,
      resolution: 'year'
    }
  });
  const {
    state: {
      sectionFilters: {
        rigSupply: { chartSettings }
      }
    },
    dispatch
  } = useStore();

  const handleDeleteRowsClick = useCallback(() => {
    if (!selectedResources?.length) return;
    selectedResources.forEach((resource) => {
      if (typeof resource === 'number') {
        dispatch({
          type: RIG_SUPPLIER_ACTIONS.REMOVE_RIG_FROM_PROJECT,
          flow: FLOW.RIG_SUPPLIER,
          payload: { projectId: params.projectId, rigId: resource }
        });
      } else {
        let ids = resource.split(',');
        if (ids?.length === 2) {
          dispatch({
            type: RIG_SUPPLIER_ACTIONS.REMOVE_REQUIREMENT,
            flow: FLOW.RIG_SUPPLIER,
            payload: {
              projectId: params.projectId,
              requirementId: parseInt(ids[0]),
              rigId: parseInt(ids[1])
            }
          });
        }
      }
    });
    setSelectedResources([]);
    setShowSelectResource(false);
  }, [selectedResources]);

  const handleResetRowsClick = useCallback(() => {
    if (!selectedResources?.length) return;
    Swal.fire({
      title: 'Are you sure?',
      text: `This action can not be reverted`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        selectedResources.forEach((resource) => {
          if (typeof resource === 'number') {
            dispatch({
              type: RIG_SUPPLIER_ACTIONS.RESET_RIG,
              flow: FLOW.RIG_SUPPLIER,
              payload: { projectId: params.projectId, rigId: resource }
            });
          } else {
            let ids = resource.split(',');
            if (ids?.length === 2) {
              dispatch({
                type: RIG_SUPPLIER_ACTIONS.RESET_REQUIREMENT,
                flow: FLOW.RIG_SUPPLIER,
                payload: {
                  projectId: params.projectId,
                  requirementId: parseInt(ids[0]),
                  rigId: parseInt(ids[1])
                }
              });
            }
          }
        });
        setSelectedResources([]);
        setShowSelectResource(false);
        snackbar({
          message: `Requirement reset`,
          color: 'success',
          duration: 5000,
          display: 'top'
        });
      }
    });
  }, [selectedResources]);

  return (
    <>
      <Grid container spacing={1}>
        <TimelineLegend setShowStatus={setShowStatus} showStatus={showStatus} />
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Typography bold>Rig Supply</Typography>
              <Typography variant={'body2'}>
                Displaying {project?.rigs?.length || 0} rigs
              </Typography>
            </Stack>
            <Stack direction={'row'} spacing={1}>
              <Stack
                direction={'row'}
                divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}
                spacing={1}
                justifyContent={'flex-end'}>
                <Button
                  variant={'text'}
                  id={'test-add-rig'}
                  key={'add-project'}
                  onClick={addRigsButtonClick}
                  startIcon={<PlusIcon color={'inherit'} />}>
                  Add rigs
                </Button>
                <Stack direction={'row'}>
                  <Button
                    variant={'text'}
                    color={'secondary'}
                    key={'select-row-button'}
                    onClick={() => setShowSelectResource(!showSelectResource)}
                    startIcon={<ChecklistIcon fontSize={'25px !important'} color={'inherit'} />}>
                    Select
                  </Button>
                  <RigsChartSettingButton />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2}>
            {showSelectResource && (
              <SelectedTimelineResourcesDisplay
                showSelectResource={showSelectResource}
                setSelectedResources={setSelectedResources}
                setShowSelectResource={setShowSelectResource}
                selectedResources={selectedResources}
                handleDeleteRowsClick={handleDeleteRowsClick}
                handleResetRowsClick={handleResetRowsClick}
              />
            )}
            <RigSupplyTimeline
              setShowSelectResource={setShowSelectResource}
              setSelectedResources={setSelectedResources}
              showSelectResource={showSelectResource}
              selectedResources={selectedResources}
              setViewRigInfo={setViewRigInfo}
              setRemoveRig={setRemoveRig}
              showStatus={showStatus}
              addRigsButtonClick={addRigsButtonClick}
              hideRequirements={hideRequirements}
              project={project}
              calView={calView}
              currentDate={currentDate}
              height={height}
              setHeight={setHeight}
              refDate={refDate}
              setRefDate={setRefDate}
              setCalView={setCalView}
              setCurrentDate={setCurrentDate}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ mb: '2.5em' }}>
          {!showSelectResource && (
            <RequirementsTimeline
              chartSettings={chartSettings}
              showStatus={showStatus}
              hideRequirements={hideRequirements}
              setHideRequirements={setHideRequirements}
              calView={calView}
              currentDate={currentDate}
              height={height}
              refDate={refDate}
            />
          )}
        </Grid>
      </Grid>
      {editEvent && (
        <EditEventModule
          handleClose={() => setEditEvent(null)}
          editEvent={editEvent}
          setEditEvent={setEditEvent}
        />
      )}
    </>
  );
}

TimelineContainer.propTypes = {
  setRemoveRig: PropTypes.func.isRequired,
  setViewRigInfo: PropTypes.func.isRequired,
  addRigsButtonClick: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

TimelineContainer.defaultProps = {};

export default TimelineContainer;
